<template>
  <b-modal
    id="timed-bn-modal"
    v-model="localShowBoolean"
    title="Edit Timing Constraint for Behaviour"
    size="lg"
    ok-title="Edit Timing"
    ok-variant="success"
    cancel-title="Dismiss"
    cancel-variant="outline-secondary"
    @shown="getData"
    @ok="updateBehaviour"
    @cancel="clearForm"
    @hidden="updateShowBoolean"
  >
    <h4>Essential</h4>
    <b-row>
      <b-col cols="3">
        <b-form-group
          label="Timing Assertion"
          label-for="timer-property"
        >
          <b-form-select
            id="timer-property"
            v-model="comparators[0]"
            :options="comparatorOptions"
            required
            style="display: inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group
          label="..."
          label-for="constraint"
        >
          <b-form-input
            id="constraint"
            v-model="constraints[0]"
            placeholder="Performance requirement - e.g. 3 seconds"
            type="text"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Conditions"
          label-for="timer-condition"
        >
          <b-form-input id="timer-condition" v-model="conditions[0]" list="source-list" />

          <datalist id="source-list">
            <option>Under <i>Peak Load</i></option>
            <option>Under <i>Nominal Load</i></option>
            <option>Under <i>Design Load</i></option>
          </datalist>
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <h4>Very Important</h4>
    <b-row>
      <b-col cols="3">
        <b-form-group
          label="Timing Assertion"
          label-for="timer-property"
        >
          <b-form-select
            id="timer-property"
            v-model="comparators[1]"
            :options="comparatorOptions"
            required
            style="display: inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group
          label="..."
          label-for="constraint"
        >
          <b-form-input
            id="constraint"
            v-model="constraints[1]"
            placeholder="Performance requirement - e.g. 3 seconds"
            type="text"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Conditions"
          label-for="timer-condition"
        >
          <b-form-input id="timer-condition" v-model="conditions[1]" list="source-list" />

          <datalist id="source-list">
            <option>Under <i>Peak Load</i></option>
            <option>Under <i>Nominal Load</i></option>
            <option>Under <i>Design Load</i></option>
          </datalist>
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <h4>Important</h4>
    <b-row>
      <b-col cols="3">
        <b-form-group
          label="Timing Assertion"
          label-for="timer-property"
        >
          <b-form-select
            id="timer-property"
            v-model="comparators[2]"
            :options="comparatorOptions"
            required
            style="display: inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group
          label="..."
          label-for="constraint"
        >
          <b-form-input
            id="constraint"
            v-model="constraints[2]"
            placeholder="Performance requirement - e.g. 3 seconds"
            type="text"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Conditions"
          label-for="timer-condition"
        >
          <b-form-input id="timer-condition" v-model="conditions[2]" list="source-list" />

          <datalist id="source-list">
            <option>Under <i>Peak Load</i></option>
            <option>Under <i>Nominal Load</i></option>
            <option>Under <i>Design Load</i></option>
          </datalist>
        </b-form-group>
      </b-col>
    </b-row>
    <h4>Desirable</h4>
    <b-row>
      <b-col cols="3">
        <b-form-group
          label="Timing Assertion"
          label-for="timer-property"
        >
          <b-form-select
            id="timer-property"
            v-model="comparators[3]"
            :options="comparatorOptions"
            required
            style="display: inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group
          label="..."
          label-for="constraint"
        >
          <b-form-input
            id="constraint"
            v-model="constraints[3]"
            placeholder="Performance requirement - e.g. 3 seconds"
            type="text"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Conditions"
          label-for="timer-condition"
        >
          <b-form-input id="timer-condition" v-model="conditions[3]" list="source-list" />

          <datalist id="source-list">
            <option>Under <i>Peak Load</i></option>
            <option>Under <i>Nominal Load</i></option>
            <option>Under <i>Design Load</i></option>
          </datalist>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'EditTimingConstraint',
  components: {
  },
  props: {
    selectedBNs: {
      type: Array,
      required: true,
    },
    showBoolean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comparators: ['<=', '<=', '<=', '<='],
      constraints: ['', '', '', ''],
      conditions: ['', '', '', ''],
      start: null,
      end: null,
      comparatorOptions: [
        { value: '<=', text: '<=' },
        { value: '<', text: '<' },
        { value: '=', text: '=' },
        { value: '>=', text: '>=' },
        { value: '>', text: '>' },
        { value: '!=', text: '!=' },
      ],
      localShowBoolean: false,
    }
  },
  watch: {
    showBoolean(newVal) {
      this.localShowBoolean = newVal
    },
  },
  methods: {
    getData() {
      // Get the start and end nodes
      if (this.selectedBNs.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        this.start = this.selectedBNs[0]
        this.end = this.selectedBNs[this.selectedBNs.length - 1]
      }
    },
    updateBehaviour(evt) {
      evt.preventDefault()
      const payload = {
        model: this.$store.state.model.id,
        start: this.start,
        end: this.end,
        comparators: this.comparators,
        constraints: this.constraints,
        conditions: this.conditions,
      }
      console.log('Update Nodes with Timing: ', payload)
      this.$http.post('/api/v2/behaviour/nodes/timing', payload).then(({ data }) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Timing Updated',
            icon: 'CheckIcon',
            text: 'Behaviour Tree View Updated',
            variant: 'success',
          },
        })
        this.$bvModal.hide('timed-bn-modal')
        this.$emit('updated-timing', data)
      })
    },
    clearForm() {
    },
    updateShowBoolean() {
      this.$emit('update-bool', this.localShowBoolean)
    },
  },
}
</script>
