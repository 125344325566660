export default {
  data: () => ({
    modalId: '',
    modalPromise: null,
  }),
  methods: {
    async showAsync() {
      this.modalPromise = new Promise(() => {})
      this.$bvModal.show(this.modalId)
      return this.modalPromise
    },
    resolveModal(result) {
      if (this.modalPromise) {
        Promise.resolve(this.modalPromise.then(() => result))
      }
    },
    rejectModal(result) {
      if (this.modalPromise) {
        Promise.reject(this.modalPromise.then(() => result))
      }
    },
  },
}
