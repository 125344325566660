<template>
  <b-modal
    id="allocate-requirements-behaviour-modal"
    title="Associate Requirement"
    size="xl"
    ok-title="Associate Requirements"
    ok-variant="success"
    cancel-variant="outline-secondary"
    cancel-title="Close"
    no-close-on-backdrop
    @ok="onSubmit"
  >
    <requirement-associate-form
      ref="associateForm"
      :is-modal="true"
      @update="updateReqs"
    />
  </b-modal>
</template>

<script>
import RequirementAssociateForm from '@/components/Forms/RequirementAssociateForm.vue'

export default {
  name: 'AssociateModal',
  components: {
    RequirementAssociateForm,
  },
  data: () => ({
    reqs: [],
  }),
  methods: {
    updateReqs(reqs) {
      this.reqs = reqs
    },
    onSubmit() {
      this.$emit('allocated', this.reqs.map(req => req.id))
      this.$bvModal.hide('allocate-requirements-behaviour-modal')
    },
  },
}
</script>
