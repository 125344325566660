<template>
  <div id="requirementAssociateForm">
    <div class="d-flex m-25">
      <div class="w-50 d-flex flex-column">
        <div>
          <h6 class="d-inline">
            Specification
          </h6>
          <span class="float-right font-small-2 text-info">
            Clear selection to show all requirements
          </span>
          <b-form-group>
            <v-select
              v-model="selectedSpecification"
              :disabled="loadingRequirements"
              :loading="loadingRequirements"
              label="title"
              :options="specifications"
            >
              <template #spinner="{ loadingRequirements }">
                <div
                  v-if="loadingRequirements"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
            </v-select>
          </b-form-group>
        </div>
        <div class="flex-grow-1 d-flex flex-column">
          <div class="w-100 d-inline-flex justify-content-between">
            <h6>
              <b-badge variant="info" class="mr-25">
                {{ requirements.length }}
              </b-badge>
              <span v-if="requirements.length !== 1">Requirements</span>
              <span v-else>Requirement</span>
            </h6>
            <b-button-group style="width: 30%">
              <b-button
                size="sm"
                variant="flat-primary"
                @click="fetchAvailableRequirements(selectedSpecification)"
              >
                <feather-icon icon="RefreshCcwIcon" class="pr-25" />
                Refresh
              </b-button>
              <b-button
                size="sm"
                variant="flat-primary"
                @click="selectedRequirements = []"
              >
                Clear selected
              </b-button>
            </b-button-group>
          </div>
          <b-form-input
            v-model="requirementSearch"
            placeholder="Search..."
            style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
          />
          <b-form-select
            v-model="selectedRequirements"
            :options="requirements"
            :disabled="loadingRequirements"
            class="h-100"
            style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
            multiple
          />
        </div>
      </div>

       <!--Assign button -->
      <div class="d-flex flex-column align-self-center mx-50">
        <b-button
          v-if="requirements"
          v-b-popover.hover.top="'Associate requirements'"
          variant="flat-success"
          :disabled="!selectedRequirements.length"
          @click="addToAssociation"
        >
          <feather-icon icon="ArrowRightIcon" size="24" />
        </b-button>
      </div>

      <!-- Associated Requirements -->
      <div class="w-50">
        <h6>
          <b-badge variant="info" class="mr-25">
            {{ associatedItems.length }}
          </b-badge>
          <span v-if="associatedItems.length !== 1">Linked Requirements</span>
          <span v-else>Linked Requirement</span>
        </h6>
        <vue-perfect-scrollbar
          v-if="associatedItems.length > 0"
          class="scroll-area"
          :settings="{
            maxScrollbarLength: 60,
            wheelPropagation: false,
          }"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in associatedItems"
              :key="index"
            >
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-between pb-0">
                  <div class="pt-25 pl-25 mb-0 pb-0">
                    <abbr
                      v-b-popover.hover.bottom.html="`${item.object_text}`"
                      :title="item.display_id"
                      class="mr-1 text-nowrap font-weight-bolder text-primary"
                    >
                      {{ item.display_id }}
                    </abbr>
                  </div>
                  <div class="w-50 d-inline-flex flex-row-reverse">
                    <b-button
                      variant="flat-danger"
                      size="sm"
                      class="p-25 ml-3"
                      @click="removeAssociation(item)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
        <div v-else>
          <p class="mt-1 ml-1 text-muted">
            No linked Requirements...
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'RequirementAssociateForm',
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    VuePerfectScrollbar,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // Specification selection --
    const selectedSpecification = ref('')
    const specifications = ref([])
    const fetchSpecifications = () => {
      store
        .dispatch('specifications/fetchSpecificationsSimple')
        .then(() => {
          specifications.value = store.state.specifications.specifications
        })
    }
    fetchSpecifications()
    // -- ./specification selection

    // Available requirements --
    const selectedRequirements = ref([])
    const requirementsCoreList = ref([])
    const requirements = ref([])
    const loadingRequirements = ref(false)
    const fetchAvailableRequirements = specification => {
      loadingRequirements.value = true
      store
        .dispatch('requirements/updateRequirementOptions', specification?.id)
        .then(() => {
          requirementsCoreList.value = []
          store.state.requirements.requirement_options.forEach(a => {
            requirementsCoreList.value.push(a)
          })
          requirements.value = requirementsCoreList.value.sort((a, b) => a.display_id.localeCompare(b.display_id))
        })
        .catch(e => {
          console.error(e)
          fetchAvailableRequirements()
        })
        .finally(() => {
          loadingRequirements.value = false
        })
    }
    // -- ./available requirements

    // Requirement context --
    const associatedItems = ref([])

    // Form elements --
    const requirementSearch = ref('')
    const removeAssociation = async item => {
      associatedItems.value = associatedItems.value.filter(i => i !== item)
      context.emit('update', associatedItems.value)
    }

    const addToAssociation = () => {
      // Add the Requirement to 'associatedItems' list if it doesn't already exist.
      selectedRequirements.value.forEach(
        x => {
          const requirement = requirements.value.find(y => y.value === x)
          console.log(requirement)
          if (associatedItems.value.filter(tI => tI.id === x).length === 0) {
            const item = {
              id: requirement.value,
              display_id: requirement.display_id,
              object_text: requirement.text,
            }
            associatedItems.value.push(item)
          }
        },
      )
      associatedItems.value.sort((a, b) => a.display_id.localeCompare(b.display_id))
      context.emit('update', associatedItems.value)
    }
    // -- ./form elements

    const getRequirementIds = async () => associatedItems.value.map(item => item.id)

    // Watchers
    watch(selectedSpecification, newSpec => {
      if (newSpec) {
        fetchAvailableRequirements(newSpec)
      } else {
        fetchAvailableRequirements()
      }
    })
    watch(requirementSearch, newValue => {
      requirements.value = requirementsCoreList.value
      if (newValue !== '') {
        // Filter Requirements list by text (display_id & object_text) or value (requirement id)
        requirements.value = requirements.value.filter(a => (
          a.text.toLowerCase().includes(newValue.toLowerCase())
          || a.value.toLowerCase() === newValue.toLowerCase()
        ))
      }
    })

    return {
      // Setup
      associatedItems,
      specifications,
      requirements,
      loadingRequirements,
      fetchAvailableRequirements,

      // Selected items
      selectedSpecification,
      selectedRequirements,

      // Form
      requirementSearch,
      addToAssociation,
      removeAssociation,

      getRequirementIds,
    }
  },
}
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  max-height: 50vh;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
