<template>
  <b-modal
    id="allocate-issues-behaviour-modal"
    title="Allocate Issues"
    size="lg"
    @ok="submitFunction"
    @shown="syncAllocations"
  >
    <b-row>
      <b-col cols="5">
        <b-form-group label="All Issues:">
          <div v-if="loading_status" class="text-center">
            <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />
          </div>
          <b-form-select v-else v-model="selected_issues" :options="all_issues" :select-size="5" multiple />
        </b-form-group>

      </b-col>
      <b-col cols="2">
        <b-button-group class="mt-5">
          <b-button
            :disabled="selected_allocated_issues.length === 0"
            @click="removeAllocated"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button
            :disabled="selected_issues.length === 0"
            @click="addAllocated"
          >
            <feather-icon icon="ArrowRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>
      <b-col cols="5">
        <b-form-group label="Associated Issues:">
          <!--<div v-if="allocated_issues.length === 0" class="text-center">-->
          <!--  <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />-->
          <!--</div>-->
          <b-form-select v-model="selected_allocated_issues" :options="allocated_issues" :select-size="5" multiple />
        </b-form-group>

      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="primary" :disabled="loading_status" @click="ok()">

        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Please wait...
        </span>

        <span v-else>
          Allocate
        </span>

      </b-button>

      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>
<script>

import { mapState } from 'vuex'

export default {
  components: {},
  props: {
    issues: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [],
    },
  },
  data: () => ({
    loading_status: false,
    selected_issues: [],
    selected_allocated_issues: [],
    all_issues: [],
    allocated_issues: [],
  }),
  computed: {},
  methods: {
    submitFunction() {
      const payload = this.allocated_issues
      this.$emit('allocated', payload)
      this.$bvModal.hide('allocate-issues-modal')
    },
    syncAllocations() {
      this.loading_status = true
      this.$store.dispatch('issues/getIssues').then(() => {
        this.all_issues = this.$store.state.issues.issues.map(x => ({
          value: [x.id, x.display_id, x.name],
          html: `${x.display_id}. ${x.name}`,
        }))
        this.loading_status = false

        this.allocated_issues = this.issues
      })
    },
    addAllocated() {
      this.selected_issues.forEach(e => {
        if (this.allocated_issues.indexOf(e) === -1) {
          this.allocated_issues.push({
            value: e,
            html: `${e[1]}. ${e[2]}`,
          })
        }
      })
      this.selected_issues = []
    },
    removeAllocated() {
      this.selected_allocated_issues.forEach(e => {
        this.allocated_issues.splice(this.allocated_issues.indexOf(e), 1)
      })
      this.selected_allocated_issues = []
    },
  },
}
</script>
