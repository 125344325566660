<template>
  <b-modal
    id="list-spec-modal"
    :title="`View Behaviours Unallocated to Selected Specification`"
    size="lg"
    class="p-0"
    lazy
    no-close-on-esc
    no-close-on-backdrop
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <div>
      <h4>
        Select Specification
      </h4>
      <v-select
        v-model="targetSpecification"
        placeholder="-- Select Specification --"
        label="title"
        :options="$store.state.specification.list"
        :value="targetSpecification"
      >
        <template #option="{ title, prefix }">
          <div class="d-flex justify-content-between">
            <span>{{ title }}</span>
            <span class="font-small-3 text-muted">{{ prefix }}</span>
          </div>
        </template>
      </v-select>

    </div>

    <!-- Buttons -->
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        variant="outline-secondary"
        @click="cancel"
      >
        Discard
      </b-button>
      <b-button variant="success" @click="onSubmit">
        Select Specification
      </b-button>
    </template>
    <!-- ./Buttons -->
  </b-modal>
</template>

<script>
import store from '@/store'
import { computed, ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  name: 'ListSpecModal',
  components: {
    vSelect,
  },
  props: {
    bt: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const targetSpecification = ref(null)

    const onShow = async () => {
      await store.dispatch('specification/getAllSpecifications')
      targetSpecification.value = null
    }

    const onSubmit = () => {
      console.log('Selecting: ', targetSpecification.value?.id, props.bt)
      context.emit('input', targetSpecification.value)
    }

    return {
      targetSpecification,

      onShow,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
