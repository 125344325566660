<template>
  <validation-observer ref="nodeValidation" #default="{ invalid, errors }">
    <b-modal
      :id="id"
      v-model="localShowBoolean"
      :title="title"
      size="lg"
      no-fade
      no-close-on-backdrop
      @ok="submitFunction"
      @shown="getData"
      @hidden="updateShowBoolean"
    >
      <!-- Event & Operator -->
      <div class="w-100 d-inline-flex justify-content-between">
        <div>
          <!-- Event -->
          <label for="bn-type-select">Type</label>
          <b-form-select id="bn-type-select" v-model="type" :options="type_options" :disabled="loading" />
        </div>
        <div>
          <!-- Operator -->
          <label for="bn-operator-select">Operator</label>
          <b-form-select id="bn-operator-select" v-model="operator" :options="operator_options" :disabled="loading" />
        </div>
      </div>

      <b-form-group class="mt-50">
        <div v-if="type !== 'FunctionNode'">
          <label for="subject-input">
            <span v-if="type === 'Quantification'">
              For each
            </span>
            <div v-else-if="type === 'Quantity'">
              <!-- Using props -->
              <b-input-group prepend="For up to" append="of" class="">
                <b-form-input v-model="quantity" type="number" />
              </b-input-group>
            </div>
            <span v-else>Subject / Performer / Actor</span>
          </label>
          <b-form-input
            id="subject-input"
            v-model="selected_performer"
            list="subject-input-list-add-behaviour"
            placeholder="Subject, performer, or actor"
          />
          <b-form-datalist id="subject-input-list-add-behaviour">
            <option v-for="performer in components" :key="performer.id">
              {{ performer.name }}
            </option>
          </b-form-datalist>

          <div class="mt-50 w-100 d-inline-flex justify-content-between">
            <!-- Instance name -->
            <b-form-group
              class="w-100 mb-0"
              label="Instance Name"
              label-for="v-instance-name"
            >
              <b-form-input
                id="v-instance-name"
                v-model="instance"
                placeholder="Instance name"
              />
            </b-form-group>

            <!-- Attribute -->
            <b-form-group
              v-if="type !== 'Quantification' && type !== 'Quantity'"
              class="w-100 ml-50 mb-0"
              label="Attribute"
              label-for="v-attribute-input"
            >
              <b-form-input
                id="v-attribute-input"
                v-model="attribute"
                placeholder="Attribute or property"
              />
            </b-form-group>
          </div>
        </div>

        <div v-if="type !== 'Quantification' && type !== 'Quantity'" class="mt-25">
          <label for="action-input">
            {{ actionLabel }}
          </label>
          <div class="w-100 d-inline-flex justify-content-between">
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `${actionLabel} is required`}"
              class="w-100 mr-50"
            >
              <b-form-input
                id="action-input"
                v-model="action"
                placeholder="Enter the action to perform"
                list="action-input-list-add-behaviour"
                required
                @change="parseAction"
              />
              <b-form-datalist id="action-input-list-add-behaviour">
                <option v-for="fn in functions" :key="fn.id">
                  {{ fn.name }}
                </option>
              </b-form-datalist>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-form-checkbox
              v-model="negated"
              class="mt-50"
              :value="true"
              :unchecked-value="false"
            >
              Negate
            </b-form-checkbox>
          </div>
        </div>
      </b-form-group>

      <hr>

      <div v-if="type !== 'FunctionNode' && type !== 'Quantification' && type !== 'Quantity'">
        <b-form-group>
          <h4 class="mb-1">
            Objects
          </h4>
          <div
            v-for="(object, index) in objects"
            :id="object.id"
            :key="object.id"
          >
            <div class="w-100 d-inline-flex">
              <b-form-input
                v-model="object.pre"
                placeholder="Preposition"
                style="width: 15%"
                class="mr-50"
              />

              <b-form-group v-if="components.map(e => e.text).includes(object.object)">
                <b-form-input
                  id="object-input"
                  v-model="object.text"
                  list="object-list"
                  placeholder="Object Name"
                />
                <b-form-datalist id="object-list">
                  <option v-for="performer in components" :key="performer.id">
                    {{ performer.name }}
                  </option>
                </b-form-datalist>
              </b-form-group>
              <b-form-group v-else description="New Entity">
                <b-form-input
                  id="object-input"
                  v-model="object.text"
                  list="object-list"
                  placeholder="Object Name"
                />
                <b-form-datalist id="object-list">
                  <option v-for="performer in components" :key="performer.id">
                    {{ performer.name }}
                  </option>
                </b-form-datalist>
              </b-form-group>

              <b-form-group class="ml-50">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HashIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="object.instance"
                    placeholder="Instance Name"
                  />
                </b-input-group>
              </b-form-group>

              <b-form-group class="ml-50 w-25">
                <b-form-input
                  v-model="object.attribute"
                  placeholder="Object Attribute"
                />
              </b-form-group>

              <b-form-group class="mx-25 align-content-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon"
                  size="sm"
                  @click="removeObject(index)"
                >
                  <feather-icon icon="TrashIcon" size="16" />
                </b-button>
              </b-form-group>
            </div>
          </div>

          <div class="w-100 d-inline-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              data-cy="btn-behaviour-add-object"
              variant="flat-primary"
              size="sm"
              @click="repeatObject"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add Object</span>
            </b-button>
          </div>
        </b-form-group>
      </div>

      <hr v-if="type !== 'FunctionNode' && type !== 'Quantification' && type !== 'Quantity'">

      <label for="add_b_clas">Classification</label>
      <b-form-select
        id="add_b_clas"
        v-model="classification"
        :options="securityClassifications"
      />

      <template v-slot:modal-footer="{ ok, cancel }">
        <div class="w-100 d-inline-flex justify-content-between">
          <div>
            <b-button
              variant="outline-primary"
              class="mr-50"
              :disabled="loading"
              @click="$bvModal.show('allocate-requirements-behaviour-modal')"
            >
              Link Requirements
              <span class="ml-50 px-25 bg-info border-info text-white font-small-3 rounded">
                {{ selected_req.length }}
              </span>
            </b-button>
            <b-button
              variant="outline-primary"
              :disabled="loading"
              @click="$bvModal.show('allocate-issues-behaviour-modal')"
            >
              Link Issues
              <span class="ml-50 px-25 bg-info border-info text-white font-small-3 rounded">
                {{ selected_issue.length }}
              </span>
            </b-button>
          </div>
          <div>
            <b-button variant="outline-secondary mr-50" @click="cancel()">
              Discard
            </b-button>
            <b-button
              data-cy="btn-behaviour-create"
              variant="success"
              class="float-right"
              :disabled="isCreateDisabled || loading || invalid"
              @click="ok()"
            >
              <span v-if="loading">
                <b-spinner small type="grow" />
                Creating...
              </span>
              <span v-else>
                Create
              </span>
            </b-button>
          </div>
        </div>
        <p class="text-danger">
          {{ errors[0] }}
        </p>
      </template>

      <requirement-behaviour-associate @allocated="fillReqs" />
      <issue-behaviour-associate @allocated="fillIssues" />
    </b-modal>
  </validation-observer>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import RequirementBehaviourAssociate from '@/components/Behaviours/Modals/RequirementBehaviourAssociate.vue'
import IssueBehaviourAssociate from '@/components/Behaviours/Modals/IssueBehaviourAssociate.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'AddBehaviourModal',
  directives: { Ripple },
  components: {
    RequirementBehaviourAssociate,
    IssueBehaviourAssociate,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: [String],
      required: true,
    },
    title: {
      type: [String],
      default: 'Add Behaviour',
    },
    p_type: {
      type: [String],
      default: 'Event',
    },
    p_direction: {
      type: [String],
      default: 'after',
    },
    showBoolean: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    // Top Row
    required,
    type: 'Event',
    loading: false,
    type_options: [
      { value: 'Event', text: 'Event' },
      { value: 'State', text: 'State' },
      { value: 'Selection', text: 'Condition' },
      { value: 'GuardedEvent', text: 'Guarded Event' },
      { value: 'Input', text: 'Input' },
      { value: 'Output', text: 'Output' },
      { value: 'Assertion', text: 'Assertion' },
      { value: 'Quantification', text: 'Quantification' },
      { value: 'Quantity', text: 'Quantity' },
      { value: 'FunctionNode', text: 'Abstract Function' },
    ],
    operator: 'no_operator',
    // Inputs
    selected_performer: '',
    action: '',
    action_fn: '',
    negated: false,
    classification: '',
    attribute: '',
    instance: '',
    quantity: 1,
    // Form Repeater
    objects: [],
    nextObjectId: 0,
    // Associators
    selected_req: [],
    selected_issue: [],
    parseResults: {},
    // quill_editor_options: {
    //   theme: 'snow',
    // },
    localShowBoolean: false,
  }),
  computed: {
    actionLabel() {
      const actionLabelMap = {
        Event: 'Action',
        GuardedEvent: 'Action',
        State: 'State',
        Selection: 'Condition',
        Condition: 'Condition',
        Assertion: 'Assertion/Predicate',
        FunctionNode: 'Function/Activity',
      }
      const result = actionLabelMap[this.type]
      return result || 'Data/Resource'
    },
    isCreateDisabled() {
      return this.loading
    },
    ...mapState({
      selected_bn: state => state.behaviours.selectedBehaviourNode,
      selected_bt: state => state.behaviours.selectedBehaviourTree,
      performers: state => state.domainModel.performers,
      functions: state => state.domainModel.functions,
      components: state => state.domainModel.components,
      defaultSecurityClassification: state => state.model.defaultSecurityClassification,
    }),
    ...mapGetters({
      behaviourOperators: 'constants/behaviourOperators',
      securityClassifications: 'constants/securityClassifications',
    }),
    operator_options() {
      return this.behaviourOperators.map(x => ({
        value: x.id,
        html: `${x.display} - ${x.text}`,
      }))
    },
  },
  watch: {
    showBoolean(newVal) {
      this.localShowBoolean = newVal
      this.type = this.p_type
    },
  },
  mounted() {
    // this.labels = this.selected_entity.context.details.labels
    this.type = this.p_type
    this.action = ''
    this.objects = []
    this.instance = ''
    this.attribute = ''
    this.classification = this.$store.state.model.defaultSecurityClassification
    this.negated = ''
    this.selected_issue = []
  },
  methods: {
    // -- API -- //
    getData() {
      // removed: should already be loaded before the modal is opened
      this.action = ''
      this.objects = []
      this.selected_req = []
      this.selected_issue = []
      this.classification = this.$store.state.model.defaultSecurityClassification
    },
    parseAction() {
      if (this.type !== 'FunctionNode') {
        const params = {
          model: this.$store.state.model.id,
          text: this.action,
        }
        this.$http.post('/api/v2/behaviour/parse_behaviour_text2', params)
          .then(({ data }) => {
            this.parseResults = data
            console.log('Parse Reuslts: ', data)
            if (data.parse_results) {
              if (data.parse_results.actions && data.parse_results.actions.length > 0) {
                const item = data.parse_results.actions[0]
                this.action = item.action
                this.action_fn = ''
                item.objects.forEach(o => {
                  this.objects.push({
                    id: this.nextObjectId,
                    pre: o.preposition,
                    text: o.object,
                    instance: o.instance || '',
                    attribute: o.attribute || '',
                  })
                  this.nextObjectId += 1
                })
                console.log('Objects on Parse: ', this.objects)
              }
            }
          })
      }
    },
    submitFunction(evt) {
      evt.preventDefault()
      this.loading = true
      const params = this.parseResults
      params.model = this.$store.state.model.id
      params.subjects = [this.selected_performer]
      params.actions = [{ name: this.action, fn: this.action_fn }]
      params.type = this.type
      params.operator = this.operator
      params.quantity = this.quantity
      params.negated = this.negated
      params.classification = this.classification
      params.attribute = this.attribute
      params.instance_name = this.instance
      params.objects = this.objects.map(e => ({
        preposition: e.pre, object: e.text, attribute: e.attribute, instance_name: e.instance,
      }))
      params.parent = this.selected_bn.details.id
      params.parent_rel = 'sequence'
      params.bts = [this.selected_bt.id]
      params.function = ''
      params.operator = this.operator
      params.direction = this.p_direction
      if (params.type === 'Event') {
        params.function = this.action
        params.objects.forEach(o => {
          if (o.preposition !== '') {
            params.function += ` ${o.preposition} ${o.object}`
          } else {
            params.function += ` ${o.object}`
          }
        })
      }
      params.precondition = []
      params.postcondition = []
      params.requirements = this.selected_req
      params.issues = this.selected_issue.map(x => x.value[0])
      if (params.type === 'FunctionNode') {
        if (params.subject !== '' && this.action === '') {
          params.actions = this.action_fn && this.action_fn !== '' ? this.action_fn : params.subjects
          params.function = this.action
          params.subject = ''
          params.subjects = []
        } else {
          params.function = this.action
          params.subject = ''
          params.subjects = []
        }
      } else if (params.type === 'Quantification') {
        params.actions = [{ name: '||', fn: '' }]
        params.action = '||'
      } else if (params.type === 'Quantity') {
        params.actions = [{ name: 'C', fn: '' }]
        params.action = 'C'
      }
      console.log('Creating BNs with: ', params)
      this.$http.post('/api/v2/behaviour/create_sao_behaviour', params)
        .then(({ data }) => {
          this.loading = false
          this.$bvModal.hide('add-behaviour-modal')
          this.$emit('added-node', data)
        })
        .catch(r => {
          this.loading = false
          console.error(`[AddBehaviour] submit failed - ${r}`)
        })
    },
    // -- Modal -- //
    fillReqs(data) {
      this.selected_req = data
      // console.warn(data)
    },
    fillIssues(data) {
      this.selected_issue = data
    },
    // -- Utility -- //
    repeatObject() {
      this.objects.push({
        id: this.nextObjectId,
        pre: '',
        instance: '',
        attribute: '',
      })
      this.nextObjectId += 1

      console.log('Objects: ', this.objects)

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeObject(index) {
      console.log('Remove Object: ', this.objects, index)
      this.objects.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    updateShowBoolean() {
      this.$emit('update-bool', this.localShowBoolean)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.modal-footer-behaviour {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
