<template>
  <b-modal
    id="update-notes-modal"
    title="Update note"
    size="lg"
    lazy
    @shown="onShown"
    @ok="onSubmit"
    @cancel="clearForm"
  >

    <b-row>
      <b-col>
        <tip-tap-editor
          id="update-note"
          v-model="content"
          min-height="12"
          max-height="12"
        />
        <!-- FIXME tip-tap-editor - min-height: 12rem -->
      </b-col>
    </b-row>

    <b-button v-b-tooltip.hover.top="'Note History'" variant="outline-warning" class="btn-icon" @click="openHistory()">
      <font-awesome-icon :icon="['fas', 'history']" />
    </b-button>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>

      <b-button variant="success" :disabled="loadingSubmit" @click="ok()">
        <span v-if="loadingSubmit">
          <b-spinner small type="grow" />
          Updating...
        </span>
        <span v-else>Update note</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  components: {
    TipTapEditor,
  },
  props: {
    focusedEntityId: {
      /* Entity to associate/de-associate Items */
      required: true,
      type: String,
    },
    urlAssociated: {
      /* API endpoint to GET and POST Items for this entity type */
      required: true,
      type: String,
    },
    note: {
      required: true,
      type: String,
    },
    noteId: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    content: '',
    loadingSubmit: false,
  }),
  methods: {
    onShown() {
      this.content = this.note
      this.loadingSubmit = false
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.loadingSubmit = true

      const payload = {
        model: this.$store.state.model.id,
        note: this.content,
      }
      this.$http
        .put(this.urlAssociated, payload)
        .then(() => {
          this.$store.dispatch('behaviours/selectBehaviourNode', this.focusedEntityId)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated note',
              text: 'Successfully upodated a note for the behaviour node',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('update-notes-modal')
          this.clearForm()
        })
        .catch(r => {
          this.clearForm()
          console.error(`[UpdateNoteModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Note',
              text: `An error occurred when attempting to update a note with the behaviour node.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    openHistory() {
      this.$router.push(
        {
          name: 'node_history',
          params: {
            modelId: sessionStorage.getItem('kompozition-workspace'),
            nodeId: this.noteId,
          },
        },
      )
    },
    clearForm() {
      this.content = ''
      this.loadingSubmit = false
    },
  },
}
</script>
