<template>
  <div class="w-100">
    <b-row>
      <b-col id="tree-key">
        <h4>Key - Coloured by Validity</h4>
        <h6>
          <b-badge style="background-color: #D8E9D2; color: #2d2d2d">
            Valid
          </b-badge>&nbsp;
          <b-badge style="background-color: #FFF2CB; color: #2d2d2d">
            Minor Uncertainty
          </b-badge>&nbsp;
          <b-badge style="background-color: #FFE497; color: #2d2d2d">
            Moderate Uncertainty
          </b-badge>&nbsp;
          <b-badge style="background-color: #F3CBCB; color: #2d2d2d">
            Major Uncertainty
          </b-badge>&nbsp;
          <b-badge style="background-color: #FFFFFF; color: #2d2d2d">
            Refinement
          </b-badge>
        </h6>
      </b-col>
      <b-col>
        <b-button-group class="float-right mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon ml-1"
            @click="refreshTree"
          >
            <feather-icon icon="RefreshCcwIcon" />
            Refresh
          </b-button>
        </b-button-group>
        <b-button-group class="float-right mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon ml-1"
            @click="showAllLinks"
          >
            <feather-icon icon="LinkIcon" class="mr-25" />
            <span v-if="showLinks">Hide X-Refs</span>
            <span v-else>Show X-Refs</span>
          </b-button>
        </b-button-group>
        <b-button-group class="float-right mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon ml-1"
            @click="showHideTiming"
          >
            <feather-icon icon="ClockIcon" class="mr-25" />
            <span v-if="showTiming">Hide Timing</span>
            <span v-else>Show Timing</span>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="selected_bt">
          <!-- BT View Graph -->
          <behaviour-tree-viewer
            :refresh-variable="refreshVar"
            :show-links="showLinks"
            :show-timing="showTiming"
            :update-object="updateObject"
            :bn-focus="bnFocus"
            :re-focus="reFocus"
            :new-focus="newFocus"
            :ref-target="refTarget"
            :ref-type="refType"
            @add-node="createBehaviour()"
            @clickNode="openSideMenu"
            @bulkSelect="openBulkMenu"
          />
        </div>
        <div v-else class="container-fluid">
          <div class="text-center mt-3">
            <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />
          </div>
        </div>
      </b-col>

      <!-- Details Panel -->
      <b-sidebar
        id="sidebar-1"
        title="Behaviour Node"
        bg-variant="white"
        shadow="true"
        width="35vw"
        right
        :backdrop="false"
        :visible="sideMenuOpened"
        @hidden="closeSideMenu"
        @clickNode="openSideMenu"
      >
        <behaviour-sidebar
          class="mx-2"
          :side-menu-data-loading="sideMenuDataLoading"
          @updated="updatedNode"
          @update_focus="focusNode"
          @post_reference="updateRefSymbology"
          @refocus="reFocusNode"
          @newfocus="focusOnNode"
        />
      </b-sidebar>

      <!-- Bulk Menu -->
      <b-sidebar
        id="sidebar-2"
        title="Bulk Association"
        bg-variant="white"
        shadow="true"
        width="35vw"
        right
        backdrop
        :visible="bulkMenuOpened"
        @hidden="closeBulkMenu"
        @clickNode="openBulkMenu"
      >
        <div v-if="bulkMenuOpened">
          <BulkOperationsSidebar
            class="mx-2"
            :side-menu-data-loading="sideMenuDataLoading"
            :nodes="bulkNodes"
          />
        </div>
      </b-sidebar>
    </b-row>
    <!--<add-behaviour />-->
  </div>
</template>

<script>
import BehaviourTreeViewer from '@/views/Behaviour/BehaviourTreeViewer.vue'
import { mapState } from 'vuex'
import AddBehaviour from '@/components/Behaviours/Modals/AddBehaviour.vue'
import BehaviourSidebar from '@/views/Behaviour/BehaviourSidebar.vue'
import BulkOperationsSidebar from '@/views/Behaviour/BulkOperationsSidebar.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  name: 'BehaviourTree',
  components: {
    BehaviourSidebar,
    BulkOperationsSidebar,
    BehaviourTreeViewer,
    // AddBehaviour,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      behaviourTrees: [],
      sideMenuOpened: false,
      sideMenuDataLoading: true,
      refreshVar: 0,
      updateObject: null,
      bnFocus: '',
      reFocus: '',
      newFocus: '',
      refTarget: null,
      refType: 'reference',
      showLinks: false,
      showTiming: false,
      selectedNode: null,
      bulkMenuOpened: false,
      bulkNodes: [],
    }
  },
  computed: {
    ...mapState({
      selected_bt: state => state.behaviours.selectedBehaviourTree,
      behaviourViews: state => state.behaviours.behaviourViews,
    }),
    bt_opts() {
      return this.behaviourViews.map(x => ({ value: x.id, text: x.name }))
    },
  },
  watch: {
    selected_bt: {
      handler(newVal) {
        this.$route.meta.pageTitle = newVal.properties.name
        this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.selected_bt && this.$route.params.behaviourTreeId) {
      await store.dispatch('behaviours/selectBehaviourTree', this.$route.params.behaviourTreeId)
    }
  },
  beforeDestroy() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.$store.commit('app/CLEAR_DYNAMIC_PAGE_TITLE')
    this.$store.commit('behaviours/CLEAR_SELECTED_TREE')
  },
  methods: {
    createBehaviour() {
      // this.$bvModal.show('add-behaviour-modal')
    },
    openSideMenu(nodeId) {
      this.selectedNode = nodeId
      this.bulkNodes = [nodeId]
      this.sideMenuDataLoading = true
      this.sideMenuOpened = true
      this.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          setTimeout(() => {
            this.sideMenuDataLoading = false
          }, 50)
        })
        .catch(() => {
          this.sideMenuDataLoading = true
        })
    },
    openBulkMenu(nodes) {
      console.log('Selected Nodes: ', nodes)
      this.bulkNodes = nodes
      this.bulkMenuOpened = true
    },
    closeSideMenu() {
      this.sideMenuOpened = false
    },
    closeBulkMenu() {
      this.bulkMenuOpened = false
    },
    refreshTree() {
      this.refreshVar += 1
    },
    showAllLinks() {
      this.showLinks = !this.showLinks
    },
    showHideTiming() {
      this.showTiming = !this.showTiming
      console.log('showTiming set to: ', this.showTiming)
    },
    updatedNode(data) {
      this.closeSideMenu()
      console.log('Updated Object: ', data)
      this.updateObject = data
    },
    focusNode(data) {
      this.bnFocus = data
    },
    reFocusNode(data) {
      this.reFocus = data
    },
    focusOnNode(data) {
      this.newFocus = data
    },
    updateRefSymbology(data, rType) {
      this.refTarget = data
      this.refType = rType
    },
  },
}
</script>
