<template>
  <b-modal
    id="edit-behaviour-modal"
    title="Edit Behaviour"
    size="lg"
    @ok="submitFunction"
    @shown="getData"
  >
    <b-form-group>
      <b-row align-h="between">
        <b-col cols="3">
          <!-- Event -->

          <label for="bn-type-select">Type:</label>
          <b-form-select id="bn-type-select" v-model="type" :options="type_options" />
        </b-col>
        <b-col cols="3">
          <!-- Operator -->

          <label for="bn-operator-select">Operator:</label>
          <b-form-select id="bn-operator-select" v-model="operator" :options="operator_options" />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group>
      <div v-if="type !== 'FunctionNode'">
        <!--<pre>Subject: {{ selected_bn.details.cpt_name }}</pre>-->
        <label for="subject-input">
          <span v-if="type !== 'Quantification'">Subject/Performer:</span>
          <span v-else>For Each:</span>
        </label>
        <b-form-input
          id="subject-input"
          v-model="selected_performer"
          list="subject-input-list-edit-behaviour"
          placeholder="Enter Performer"
        />
        <b-form-datalist id="subject-input-list-edit-behaviour">
          <option v-for="performer in performers" :key="performer.value">
            {{ performer.text }}
          </option>
        </b-form-datalist>

        <!--<pre>Attribute: {{ selected_bn.details.attribute }}</pre>-->
        <div v-if="type !== 'Quantification'">
          <label for="attribute-input">
            Attribute:
          </label>
          <b-form-input id="attribute-input" v-model="attribute" placeholder="Enter the attribute/property" />
        </div>
      </div>
      <div v-if="type !== 'Quantification'">
        <!--<pre>Action: {{ selected_bn.details.behaviour_name }}</pre>-->
        <label for="action-input">
          <span v-if="type !== 'Input' && type !== 'Output'">Action:</span>
          <span v-else>Data/Resource:</span>
        </label>
        <!--<pre>{{ negate }}</pre>-->
        <b-row>
          <b-col cols="10">
            <b-form-input id="action-input" v-model="action" placeholder="Enter the action to perform" />
          </b-col>
          <b-col cols="2">
            <!--<pre>{{ selected_bn.details.negated === 'True' }}</pre>-->
            <b-form-checkbox
              v-model="negated"
              class="mt-50"
              :value="true"
              :unchecked-value="false"
            >
              Negate
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>

    </b-form-group>

    <hr>

    <div v-if="type !== 'FunctionNode'">
      <b-form-group>
        <h4 class="mb-1">
          Objects
        </h4>
        <!--<b-row class="mb-1">-->
        <!--  <b-col cols="3">-->
        <!--    <b-form-input placeholder="Preposition" />-->
        <!--  </b-col>-->
        <!--  <b-col>-->
        <!--    <b-form-input placeholder="Object Name" />-->
        <!--  </b-col>-->
        <!--</b-row>-->

        <div
          v-for="(object, index) in objects"
          :id="object.id"
          :key="object.id"
          class="mb-1"
        >
          <b-row>
            <b-col cols="3">
              <b-form-input
                v-model="object.pre"
                placeholder="Preposition"
              />
            </b-col>
            <b-col cols="9">
              <b-form-input
                v-model="object.text"
                list="object-input-list-edit"
                placeholder="Object Name"
              />
              <b-form-datalist id="object-input-list-edit">
                <option v-for="performer in performers" :key="performer.value">
                  {{ performer.text }}
                </option>
              </b-form-datalist>
            </b-col>
          </b-row>

          <b-row class="mt-50">
            <b-col cols="3" />
            <b-col>
              <b-form-input
                v-model="object.attribute"
                placeholder="Object Attribute"
              />
            </b-col>

            <b-col
              cols="1"
              class="pl-0"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="h-100"
                size="sm"
                @click="removeObject(index)"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-button>
            </b-col>
          </b-row>

        </div>

        <b-row align-h="center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            @click="repeatObject"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Add Object</span>
          </b-button>
        </b-row>
      </b-form-group>
    </div>

    <!-- <pre>{{ selected_bn }}</pre> -->

    <!--<pre>{{ objects }}</pre>-->
    <!--<pre>{{ performers }}</pre>-->
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button class="font-small-3" size="sm" variant="outline-primary" @click="$bvModal.show('allocate-requirements-behaviour-modal')">
        Associate Requirements
        <b-badge variant="outline-primary">
          {{ selected_req.length }}
        </b-badge>
      </b-button>
      <b-button class="font-small-3 mr-auto" size="sm" variant="outline-primary" @click="$bvModal.show('allocate-issues-behaviour-modal')">
        Allocate Issues
        <b-badge variant="outline-primary">
          {{ selected_issue.length }}
        </b-badge>
      </b-button>
      <b-button variant="primary" class="float-right" @click="ok()">
        Update
      </b-button>
      <b-button class="float-right mr-1" @click="cancel()">
        Cancel
      </b-button>
    </template>

    <requirement-behaviour-associate :reqs="selected_req" @allocated="fillReqs" />
    <issue-behaviour-associate :issues="selected_issue" @allocated="fillIssues" />
  </b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import RequirementBehaviourAssociate from '@/components/Behaviours/Modals/RequirementBehaviourAssociate.vue'
import IssueBehaviourAssociate from '@/components/Behaviours/Modals/IssueBehaviourAssociate.vue'

export default {
  name: 'EditBehaviourModal',
  directives: {
    Ripple,
  },
  components: {
    RequirementBehaviourAssociate,
    IssueBehaviourAssociate,
  },
  data: () => ({
    performers: [],
    // Top Row
    type: 'Event',
    type_options: [
      { value: 'Event', text: 'Event' },
      { value: 'State', text: 'State' },
      { value: 'Selection', text: 'Condition' },
      { value: 'GuardedEvent', text: 'Guarded Event' },
      { value: 'Input', text: 'Input' },
      { value: 'Output', text: 'Output' },
      { value: 'Assertion', text: 'Assertion' },
      { value: 'Quantification', text: 'Quantification' },
      { value: 'FunctionNode', text: 'Abstract Function' },
    ],
    operator: 'no_operator',
    // Inputs
    selected_performer: '',
    action: '',
    behaviour_name: '',
    action_fn: '',
    negated: false,
    attribute: '',
    // Form Repeater
    objects: [],
    nextObjectId: 0,
    // Associators
    selected_req: [],
    selected_issue: [],
    parseResults: {},
    // quill_editor_options: {
    //   theme: 'snow',
    // },
  }),
  computed: {
    ...mapState({
      selected_bn: state => state.behaviours.selectedBehaviourNode,
      selected_bt: state => state.behaviours.selectedBehaviourTree,
    }),
    ...mapGetters({
      behaviourOperators: 'constants/behaviourOperators',
    }),
    operator_options() {
      // eslint-disable-next-line camelcase
      return this.behaviourOperators.map(x => ({
        value: x.id,
        text: `${x.display} - ${x.text}`,
      }))
    },
  },
  mounted() {
    // this.labels = this.selected_entity.context.details.labels
  },
  methods: {
    // -- API -- //
    getData() {
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/domain_model/get_composition_subtree', { params: { model: modelId } })
        .then(({ data }) => {
          this.performers = data.nodes.map(x => ({
            value: x.id,
            text: x.name,
          }))
        })

      this.type = this.selected_bn.details.type
      this.operator = this.selected_bn.details.operator
      this.selected_performer = this.selected_bn.details.cpt_name
      this.action = this.selected_bn.details.behaviour_name
      this.behaviour_name = this.selected_bn.details.behaviour_name
      this.negated = (this.selected_bn.details.negated && (this.selected_bn.details.negated === 'True' || this.selected_bn.details.negated === 'true' || this.selected_bn.details.negated === true))
      this.attribute = this.selected_bn.details.attribute

      const objects = this.selected_bn.rel_parts
      this.objects = []
      for (let i = 0; i < objects.length; i++) {
        this.objects.push({
          pre: objects[i].rel_obj_props.preposition,
          text: objects[i].rel_obj_props.object_name,
          attribute: objects[i].rel_obj_props.attribute,
        })
      }

      this.selected_req = this.selected_bn.requirements.map(x => ({
        value: [x.id, x.display_id],
        text: x.display_id,
      }))
      this.selected_issue = this.selected_bn.issues.map(x => ({
        value: [x.id, x.display_id, x.name],
        html: `${x.display_id}. ${x.name}`,
      }))
    },
    // Don't pasre actions on Edit
    submitFunction(evt) {
      evt.preventDefault()
      // console.log('Do It!!!')
      const node = this.selected_bn.details.id
      const params = {}
      params.model = this.$store.state.model.id
      if (this.type !== 'FunctionNode') {
        params.component = this.selected_performer
        params.type = this.type
        // Flag IO resource change
        if (this.type === 'Input' || this.type === 'Output') {
          if (this.action !== this.behaviour_name) {
            params.io_resource = this.action
          }
        }
        params.behaviour_name = this.action
        params.operator = this.operator
        params.negated = this.negated
        params.attribute = this.attribute
        params.objects = this.objects.map(e => ({ preposition: e.pre, object: e.text, attribute: e.attribute }))
        params.parent = this.selected_bn.details.id
        params.parent_rel = 'sequence'
        params.operator = this.operator
        params.bts = [this.selected_bt.id]
        params.function = ''
        params.direction = 'after'
        if (params.type === 'Event') {
          params.function = this.action
          params.objects.forEach(o => {
            if (o.preposition !== '') {
              params.function += ` ${o.preposition} ${o.object}`
            } else {
              params.function += ` ${o.object}`
            }
          })
        }
        params.precondition = []
        params.postcondition = []
        params.requirements = this.selected_req.map(x => x.value[0])
        params.issues = this.selected_issue.map(x => x.value[0])
      } else {
        params.component = this.action
        params.type = 'FunctionNode'
        params.behaviour_name = this.action
        params.operator = this.operator
        params.attribute = ''
        params.objects = []
        params.parent = this.selected_bn.details.id
        params.parent_rel = 'sequence'
        params.operator = this.operator
        params.negated = this.negated
        params.bts = [this.selected_bt.id]
        params.function = this.action
        params.direction = 'after'
        params.precondition = []
        params.postcondition = []
        params.requirements = this.selected_req
        params.issues = this.selected_issue
      }

      this.$http.put(`/api/v2/behaviour/update_behaviour/${node}`, params)
        .then(({ data }) => {
          console.log('Done: ', data)
          this.$bvModal.hide('edit-behaviour-modal')
          this.$emit('updated-node', data)
        })
    },
    // -- Modal -- //
    fillReqs(data) {
      this.selected_req = data
      // console.warn(data)
    },
    fillIssues(data) {
      this.selected_issue = data
    },
    // -- Utility -- //
    repeatObject() {
      this.objects.push({
        id: this.nextObjectId += 1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeObject(index) {
      this.objects.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.modal-footer-behaviour {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
