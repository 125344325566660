<template>
  <b-modal
    id="function-select-modal"
    v-model="localShowBoolean"
    title="Select Function to Instantiate"
    size="lg"
    @ok="postSelection"
    @shown="getData"
    @hidden="updateShowBoolean"
  >
    <b-form-input
      v-model="query"
      size="lg"
      type="search"
      placeholder="Search..."
    />
    <b-form-select
      v-model="value"
      :options="makeSetArray(visibleOptions)"
      :select-size="6"
      size="lg"
    />

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="primary" @click="ok()">
        Select
      </b-button>

      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FunctionSelect',
  props: {
    showBoolean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      value: '',
      localShowBoolean: false,
    }
  },
  computed: {
    options() {
      return this.functions.map(x => ({
        value: x.id,
        text: x.name,
      }))
    },
    visibleOptions() {
      return this.functions.map(x => ({
        value: x.id,
        text: x.name,
      }))
        .filter(item => String(item)
          .toLowerCase()
          .includes(this.query.toLowerCase()))
    },
    ...mapState({
      functions: state => state.domainModel.functions,
    }),
  },
  watch: {
    showBoolean(newVal) {
      this.localShowBoolean = newVal
    },
  },
  methods: {
    getData() {
      this.$store.dispatch('domainModel/getComponents').then(({ data }) => {
        this.functions = data
      })
    },
    makeSetArray: arr => [...new Set(arr)],
    postSelection(evt) {
      evt.preventDefault()
      const selected = this.value
      console.log('Selected: ', selected)
      this.$emit('sel-fn', selected)
    },
    updateShowBoolean() {
      this.$emit('update-bool', this.localShowBoolean)
    },
  },
}
</script>
