<template>
  <div>
    <div v-if="copying">
      Copying
      <b-spinner large type="grow" />
    </div>
    <div v-if="moving">
      Moving
      <b-spinner large type="grow" />
    </div>
    <div v-if="integrating">
      Integrating
      <b-spinner large type="grow" />
    </div>
    <div v-if="testgen">
      Generating Tests
      <b-spinner large type="grow" />
    </div>
    <div v-if="highlighting">
      <span class="text-primary">Identifying Nodes to highlight</span>
      <b-spinner large type="grow" />
    </div>

    <div>
      <div id="tree" ref="tree" />
    </div>
    <AddBehaviourModal
      id="add-behaviour-modal"
      title="Add Behaviour"
      @added-node="postAdd"
    />
    <AddBehaviourModal
      id="add-precond-modal"
      title="Add Precondition"
      p_type="Selection"
      p_direction="before"
      @added-node="postAddPrecond"
    />
    <edit-behaviour @updated-node="postUpdate" />
    <EditTimingConstraint
      :selected-b-ns="selectedNodeIds"
      @updated-timing="postTiming"
    />
    <delete-behaviour @deleted-node="postDel" />
    <InterfaceSelect @input="instantiateInterface" />
    <FunctionSelectModal @sel-fn="instantiateFn1" />
    <InstantiateFunctionBehaviour
      id="instantiate-fn-modal"
      title="Instantiate Function"
      p_type="Event"
      p_direction="after"
      :p_performers="instFnPerformers"
      :p_behaviour="instFnName"
      :p_reqs="instFnReqs"
      :p_iss="instFnIss"
      @instantiated="postAdd"
    />
    <CopyBehaviour @copy-node="postCopy" />
    <RefineBehaviourModal @refined="updateRefinement" />
    <GenerateRequirementsBT />
    <ShowTestsModal @testedness="showTests" />
    <ShowEnablementModal @enablement="showEnablement" />
    <ViewRequirementModal :bt="selectedBT.id" @requirementSelected="highlight_nodes" />
    <ListSpecModal :bt="selectedBT.id" @input="highlight_unallocated_nodes" />
  </div>
</template>

<script>
import OrgChart from '@balkangraph/orgchart.js'
import AddBehaviourModal from '@/components/Behaviours/Modals/AddBehaviour.vue'
import { mapGetters, mapState } from 'vuex'
import axiosIns from '@/libs/axios'
import EditBehaviour from '@/components/Behaviours/Modals/EditBehaviour.vue'
import InterfaceSelect from '@/components/Domain/Modals/Interfaces/InterfaceSelect.vue'
import FunctionSelectModal from '@/components/Domain/Modals/FunctionSelectModal.vue'
import InstantiateFunctionBehaviour from '@/components/Behaviours/Modals/InstantiateFunctionBehaviour.vue'
import DeleteBehaviour from '@/components/Behaviours/Modals/DeleteBehaviour.vue'
import CopyBehaviour from '@/components/Behaviours/Modals/CopyBehaviour.vue'
import RefineBehaviourModal from '@/components/Behaviours/Modals/RefineBehaviourModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditTimingConstraint from '@/components/Behaviours/Modals/EditTimingConstraint.vue'
import GenerateRequirementsBT from '@/components/Behaviours/Modals/GenerateRequirementsBT.vue'
import ShowTestsModal from '@/components/Behaviours/Modals/showTests.vue'
import ShowEnablementModal from '@/components/Behaviours/Modals/ShowEnablement.vue'
import ViewRequirementModal from '@/components/Behaviours/Modals/ViewRequirementModal.vue'
import ListSpecModal from '@/components/Behaviours/Modals/ListSpecModal.vue'

export default {
  name: 'BehaviourTreeViewer',
  components: {
    ViewRequirementModal,
    AddBehaviourModal,
    EditBehaviour,
    DeleteBehaviour,
    CopyBehaviour,
    RefineBehaviourModal,
    InterfaceSelect,
    FunctionSelectModal,
    InstantiateFunctionBehaviour,
    EditTimingConstraint,
    GenerateRequirementsBT,
    ShowTestsModal,
    ShowEnablementModal,
    ListSpecModal,
  },
  props: {
    refreshVariable: {
      type: Number,
      default: 0,
    },
    showLinks: {
      type: Boolean,
      default: false,
    },
    showTiming: {
      type: Boolean,
      default: false,
    },
    updateObject: {
      type: Object,
      default: null,
    },
    bnFocus: {
      type: String,
      default: '',
    },
    reFocus: {
      type: String,
      default: '',
    },
    newFocus: {
      type: String,
      default: '',
    },
    refTarget: {
      type: String,
      default: '',
    },
    refType: {
      type: String,
      default: 'reference',
    },
  },
  data() {
    return {
      useJointJs: false,
      chart: false,
      dragNode: '',
      dropNode: '',
      selParent: '',
      selNode: '',
      selectedNodeIds: [],
      nodes: [],
      newNode: {},
      layoutIcon: '',
      nodeMap: {},
      focusNode: '',
      colourBy: 'validity',
      testedness: {},
      enablement: {},
      copying: false,
      integrating: false,
      moving: false,
      testgen: false,
      highlighting: false,
      instFnPerformers: [],
      instFnName: '',
      instFnReqs: [],
      instFnIss: [],
      passPercentage: 0,
      failPercentage: 0,
      noRunPercentage: 0,
      partialPercentage: 0,
      naPercentage: 0,
      onePercentage: 0,
      twoPercentage: 0,
      threePercentage: 0,
      fourPercentage: 0,
      fivePercentage: 0,
    }
  },
  computed: {
    ...mapState({
      selectedBT: state => state.behaviours.selectedBehaviourTree,
      selectedNode: state => state.behaviours.selectedBehaviourNode,
    }),
    ...mapGetters({
      behaviourOperators: 'constants/behaviourOperators',
    }),
  },
  watch: {
    selectedBT(newVal) {
      console.debug('[BehaviourTreeViewer] Tree changed: ', newVal)
      setTimeout(() => {
        this.getTreeData()
        this.oc(this.$refs.tree, this.nodes)
      }, 800)
    },
    focusNode(newVal) {
      console.log('Focus: ', newVal)
      this.chart.center(newVal, {}, () => {
        const focusedElements = document.querySelectorAll('.focused')
        for (let i = 0; i < focusedElements.length; i++) {
          focusedElements[i].classList.remove('focused')
        }
        const nodeElement = this.chart.getNodeElement(newVal)
        nodeElement.classList.add('focused')
      })
    },
    refreshVariable() {
      this.refresh()
    },
    showLinks() {
      console.log('Refs: ', this.selectedBT)
      if (this.showLinks) {
        if (this.selectedBT.references) {
          this.selectedBT.references.forEach(ref => {
            if (ref.rel_type === 'REVERT' || ref.rel_type === 'REVERSION') {
              this.chart.addClink(ref.source, ref.target, '', 'blue').draw(OrgChart.action.update)
            } else {
              this.chart.addSlink(ref.source, ref.target, '', 'blue').draw(OrgChart.action.update)
            }
          })
        }
      } else {
        this.refresh()
      }
    },
    showTiming() {
      console.log('Timings: ', this.selectedBT.timing)
      if (this.showTiming) {
        if (this.selectedBT.timing) {
          this.selectedBT.timing.forEach(ref => {
            const label = ref.rel_props.timing_string
            this.chart.addSlink(ref.source, ref.target, label, 'orange').draw(OrgChart.action.update)
          })
        }
      } else {
        this.refresh()
      }
    },
    updateObject() {
      console.log('Updating')
      this.postUpdate()
    },
    bnFocus() {
      this.focusOnThisNode()
    },
    reFocus() {
      this.reFocusNode()
    },
    newFocus() {
      this.focusNode = this.newFocus
    },
    refTarget() {
      this.updateRefSymbology()
    },
  },
  mounted() {
    OrgChart.templates.bt_fn_node = { ...OrgChart.templates.ana }
    OrgChart.templates.bt_fn_node.size = [300, 80]
    OrgChart.templates.bt_fn_node.node = '<rect fill="#FFFFF5" x="0" y="0" width="300" height="80" rx="2" ry="2" style="stroke: dimgrey;stroke-width: 2;"></rect>'
    OrgChart.templates.bt_fn_node.component = '<text text-overflow="multiline" width="260"  style="font-size: 1em;" x="140" y="35" dominant-baseline="middle" text-anchor="middle">{val}</text>'
    OrgChart.templates.bt_fn_node.nodeMenuButton = '<g style="cursor:pointer;" transform="matrix(1,0,0,1,275,70)" control-node-menu-id="{id}">'
                                            + '<rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect>'
                                            + '<circle cx="0" cy="0" r="2" fill="#696969"></circle><circle cx="7" cy="0" r="2" fill="#696969"></circle><circle cx="14" cy="0" r="2" fill="#696969"></circle></g>'
    OrgChart.templates.bt_fn_node.nodeCircleMenuButton = {
      radius: 14,
      x: 302,
      y: 40,
      color: '#fff',
      stroke: '#696969',
    }

    OrgChart.templates.bt = { ...OrgChart.templates.ana }
    OrgChart.templates.bt.size = [300, 120]
    OrgChart.templates.bt.defs = '<marker id="arrow" viewBox="0 0 10 10" refX="8" refY="5" markerWidth="8" markerHeight="8" orient="auto-start-reverse"><path fill="dimgrey" d="M 0 0 L 10 5 L 0 10 z" /></marker><marker id="dotBlue" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="5" markerHeight="5"> <circle cx="5" cy="5" r="5" fill="#039BE5" /></marker>'
    OrgChart.templates.bt.link = '<path marker-start="url(#dotBlue)" marker-end="url(#arrow)"   stroke-linejoin="round" stroke="dimgrey" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />'
    OrgChart.templates.bt.node = '<rect fill="#FFFFFF" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt.component = '<g><text text-overflow="ellipsis" width="270"  style="font-size: 0.9em;" x="145" y="20" dominant-baseline="middle" text-anchor="middle">{val}</text><line x1="0" y1="30" x2="300" y2="30" stroke="dimgrey" stroke-width="0.5px"/></g>'
    OrgChart.templates.bt.start_symbol = '<text text-overflow="ellipsis" width="40"  style="font-size: 1.5em;"  x="25" y="50" dominant-baseline="middle" text-anchor="middle">{val}</text>'
    OrgChart.templates.bt.name = '<foreignObject x="43" y="40" width="214" height="70">'
                                  + '<div style="width: 214px; height: 70px; font-size: 0.9em; color: black!important; overflow: auto; text-align: center;">'
                                  + '{val}'
                                  + '</div></foreignObject>'
    OrgChart.templates.bt.end_symbol = '<text text-overflow="ellipsis" width="40"  style="font-size: 1.5em;"  x="275" y="50" dominant-baseline="middle" text-anchor="middle">{val}</text>'

    OrgChart.templates.bt.nodeMenuButton = '<g style="cursor:pointer;" transform="matrix(1,0,0,1,275,110)" control-node-menu-id="{id}">'
                                            + '<rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect>'
                                            + '<circle cx="0" cy="0" r="2" fill="#696969"></circle><circle cx="7" cy="0" r="2" fill="#696969"></circle><circle cx="14" cy="0" r="2" fill="#696969"></circle></g>'
    OrgChart.templates.bt.nodeCircleMenuButton = {
      radius: 14,
      x: 302,
      y: 60,
      color: '#fff',
      stroke: '#696969',
    }
    OrgChart.templates.bt.operator = '<text text-overflow="ellipsis" width="70"  style="font-size: 1em;" x="280" y="15" dominant-baseline="left" text-anchor="left">{val}</text>'

    OrgChart.templates.bt_green = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_green.node = '<rect fill="#D8E9D2" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_yellow = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_yellow.node = '<rect fill="#FFF2CB" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_amber = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_amber.node = '<rect fill="#FFE497" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_red = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_red.node = '<rect fill="#F3CBCB" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_white = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_white.node = '<rect fill="#FFFFFF" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_grey = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_grey.node = '<rect fill="#D8D8D8" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_violet = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_violet.node = '<rect fill="#D0BDF0" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_highlight = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_highlight.node = '<rect fill="#16FFFF" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: #0080FF;stroke-width: 1;"></rect>'

    this.layoutIcon = '<svg width="16" height="16" x="0px" y="0px" fill="#ccc" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="m397.800781 311.417969v-85.476563h-126.800781v-43.359375h30.03125v-90.0625h-90.0625v90.0625h30.03125v43.359375h-126.800781v85.476563h-30.699219v90.0625h90.0625v-90.0625h-29.363281v-55.476563h96.800781v55.476563h-30.03125v90.0625h90.0625v-90.0625h-30.03125v-55.476563h96.800781v55.476563h-29.363281v90.0625h90.0625v-90.0625zm-156.832031-188.898438h30.0625v30.0625h-30.0625zm-97.40625 248.960938h-30.0625v-30.0625h30.0625zm127.46875 0h-30.0625v-30.0625h30.0625zm127.46875 0h-30.0625v-30.0625h30.0625zm0 0" fill="#ccc"/></svg>'
    this.upIcon = '<svg width="16" height="16" version="1.1" id="ios7_x5F_arrows_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve"><style>.st0{display:none}.st1{display:inline}</style><g id="_x34__1_"><path d="M64.1 0C28.8 0 .2 28.7.2 64s28.6 64 63.9 64S128 99.3 128 64c-.1-35.3-28.7-64-63.9-64zm0 122.7C31.7 122.7 5.5 96.4 5.5 64c0-32.4 26.2-58.7 58.6-58.7 32.3 0 58.6 26.3 58.6 58.7-.1 32.4-26.3 58.7-58.6 58.7zm-.3-93.9L33.1 59.5l3.8 3.8 24.5-24.5V104h5.3V39.4l24 24 3.8-3.8-30.7-30.8z"  fill="#ccc" icon_35_"/></g></svg>'
    this.homeIcon = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2"><path d="M55.579 31.579a2 2 0 0 1 .208 2.583l-1.284 1.781a1.996 1.996 0 0 1-3.036.245A462554.14 462554.14 0 0 1 32 16.722L12.533 36.188a1.996 1.996 0 0 1-3.036-.245l-1.284-1.781a2 2 0 0 1 .208-2.583L32 8l23.579 23.579z" style="fill:none;stroke:#222a33;stroke-width:2px"/><path d="M13.977 34.745 32 16.722l18.023 18.023v20.002a2.25 2.25 0 0 1-.66 1.593 2.25 2.25 0 0 1-1.593.66H16.23a2.25 2.25 0 0 1-1.593-.66 2.25 2.25 0 0 1-.66-1.593V34.745zM20.736 19.264l-7.885 7.885V15.322h7.885v3.942z" style="fill:none;stroke:#222a33;stroke-width:2px"/><path d="M37 44.5a1.503 1.503 0 0 0-1.5-1.5h-7a1.503 1.503 0 0 0-1.5 1.5V57h10V44.5z" style="fill:none;stroke:#7a7a7a;stroke-width:1px"/></svg>'
    this.mergeIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" viewBox="0 0 24 24"><path d="m5 14 4-4-4-4v2H0v4h5v2zM19 6l-4 4 4 4v-2h5V8h-5V6zM11 0h2v20h-2z"/></svg>'
    this.moveIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" viewBox="0 0 24 24"><path d="M18.364 8.465 16.95 9.879 18.071 11H13V5.928l1.122 1.122 1.414-1.414L12 2.101 8.464 5.636 9.878 7.05 11 5.928V11H5.929L7.05 9.879 5.636 8.465 2.1 12l3.536 3.535 1.414-1.414L5.929 13H11v5.072L9.878 16.95l-1.414 1.414L12 21.899l3.536-3.535-1.414-1.414L13 18.072V13h5.071l-1.121 1.121 1.414 1.414L21.9 12l-3.536-3.535z"/></svg>'
    this.copyIcon = '<svg  width="16" height="16" fill="#ccc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 10v10a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1zM6 14H5V5h9v1a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2z"/></svg>'
    this.ifIcon = '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve"><style>.st0{display:none}.st1{display:inline}</style><g id="_x33_4_1_"><path d="M33.8 53.3 30 49.5-.1 79.7 30 109.9l3.8-3.8L10 82.3h63.2v-5.2H10l23.8-23.8zm94.1-5.1L97.8 18.1 94 21.9l23.8 23.8h-63v5.2h63L94.1 74.8l3.8 3.8L128 48.5v-.3h-.1z" id="icon_8_"/></g></svg>'
    this.exportIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16" fill="#ccc"><path d="M256 73.82a182.18 182.18 0 1 0 0 364.36c100.608 0 182.18-81.571 182.18-182.18A182.183 182.183 0 0 0 256 73.82zm-47.408 98.965 38.522-38.514a12.802 12.802 0 0 1 9.106-3.77h.193a12.854 12.854 0 0 1 9.122 3.77l38.506 38.514a12.896 12.896 0 1 1-18.237 18.237l-17.016-17.024v69.1a12.902 12.902 0 1 1-25.805 0v-68.22l-16.146 16.144a12.899 12.899 0 1 1-18.245-18.237zm146.487 155.259c0 17.411-17.077 31.051-38.883 31.051H195.803c-21.797 0-38.873-13.64-38.873-31.051V244.23c0-17.41 17.076-31.052 38.873-31.052h16.26a32.549 32.549 0 0 0 5.66.528 32.203 32.203 0 0 0 5.617-.528h.747v25.199h-28.283c-8.825 0-13.676 4.394-13.676 5.853v83.813c0 1.459 4.851 5.844 13.676 5.844h120.4c8.825 0 13.677-4.393 13.677-5.844V244.23c0-1.459-4.852-5.853-13.676-5.853h-28.52v-25.199h1.74a29.75 29.75 0 0 0 11.153 0h15.627c21.797 0 38.875 13.642 38.875 31.052v83.813z" data-name="File Export"/></svg>'
    this.testIcon = '<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 4v14a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V4h4z" stroke="#ccc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 14h4" stroke="#ccc" stroke-width="2"/><path d="M3 4h18M10 4v14a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V4h4z" stroke="#ccc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 8h4" stroke="#ccc" stroke-width="2"/></svg>'
    this.tickIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" viewBox="0 0 24 24"><path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z"/></svg>'
    this.fnIcon = '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve"  width="16" height="16"  style="shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd" viewBox="0 0 6.827 6.827"><defs><style>.fil0{fill:#212121;fill-rule:nonzero}</style></defs><g id="Layer_x0020_1"><g id="_267713216"><path id="_267712808" class="fil0" d="M3.413 1.84a.419.419 0 0 1 .297.718.419.419 0 0 1-.717-.297.419.419 0 0 1 .42-.42zm.147.274a.206.206 0 0 0-.354.147.206.206 0 0 0 .354.146.206.206 0 0 0 0-.293z"/><path id="_267712976" class="fil0" d="M3.37 5.03v.837h-.213V5.03z"/><path id="_267713120" class="fil0" d="M3.632 5.03v.837h-.213V5.03z"/><path id="_267713456" class="fil0" d="M3.336 4.767h.155a9.677 9.677 0 0 0 .131-.349l.043-.12.11.063.496.286.03-.306-.46-.422-.048-.044.016-.061c.169-.647.197-1.18.108-1.634a2.337 2.337 0 0 0-.504-1.057c-.25.306-.424.65-.504 1.057-.088.453-.06.987.109 1.634l.016.061-.048.044-.46.422.03.306.495-.286.11-.064.044.121a9.386 9.386 0 0 0 .13.35zm.227.213h-.37l-.028-.066a10.586 10.586 0 0 1-.118-.305l-.527.305-.143.082-.016-.164-.052-.523-.006-.052.04-.036.453-.416C2.633 3.153 2.609 2.61 2.7 2.14c.095-.49.315-.894.634-1.25l.08-.09.078.09c.32.356.54.76.635 1.25.09.469.067 1.013-.096 1.665l.453.416.04.036-.006.052-.052.523-.017.164-.142-.082-.528-.305a10.396 10.396 0 0 1-.117.305l-.028.066h-.071z"/></g></g><path style="fill:none" d="M0 0h6.827v6.827H0z"/></svg>'
    this.cogIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" viewBox="0 0 512 512"><g data-name="Setting Cog"><path d="M256 229.123a34.247 34.247 0 1 1-34.251 34.251A34.246 34.246 0 0 1 256 229.123z"/><path d="M256 73.82A182.18 182.18 0 1 0 438.18 256 182.182 182.182 0 0 0 256 73.82zm95.73 208.653-25.875 1.67a72.5 72.5 0 0 1-5.765 13.94l17.112 19.485-27.009 27.017-19.486-17.12a71.936 71.936 0 0 1-13.939 5.765l-1.67 25.875h-38.205l-1.68-25.875a72.325 72.325 0 0 1-13.93-5.765l-19.476 17.12-27.01-27.026 17.104-19.477a71.904 71.904 0 0 1-5.756-13.939l-25.884-1.678v-38.199l25.884-1.67a72.478 72.478 0 0 1 5.756-13.939l-17.103-19.467 27.009-27.027 19.476 17.113a72.14 72.14 0 0 1 13.94-5.757l1.67-25.884H275.1l1.67 25.884a71.907 71.907 0 0 1 13.939 5.757l19.486-17.113 27.009 27.027-17.113 19.468a72.147 72.147 0 0 1 5.765 13.939l25.875 1.67z"/></g></svg>'
    this.rightIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ccc" viewBox="0 0 32 32"><g data-name="19-Arrow Right"><path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"/><path d="m26.71 15.29-7-7-1.42 1.42 5.3 5.29H5v2h18.59l-5.29 5.29 1.41 1.41 7-7a1 1 0 0 0 0-1.41z"/></g></svg>'
    this.revertIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ccc" viewBox="0 0 32 32"><path d="M12 3a8.959 8.959 0 0 0-7 3.339V4H3v6h6V8H6.274a6.982 6.982 0 1 1-1.054 5.751l-1.936.5A9 9 0 1 0 12 3z"/></svg>'
    this.refineIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ccc" viewBox="0 0 32 32"><path d="M12 3a8.959 8.959 0 0 0-7 3.339V4H3v6h6V8H6.274a6.982 6.982 0 1 1-1.054 5.751l-1.936.5A9 9 0 1 0 12 3z"/></svg>'
    this.reqIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ccc" viewBox="0 0 32 32"><g data-name="33-Notice"><path d="M25 0H7a7 7 0 0 0-7 7v18a7 7 0 0 0 7 7h18a7 7 0 0 0 7-7V7a7 7 0 0 0-7-7zm5 25a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5h18a5 5 0 0 1 5 5z"/><path d="M16 5a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V8a3 3 0 0 0-3-3zm1 11a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0zM16 21a3 3 0 0 0-2.79 1.89 1 1 0 0 0-.21.61v.5a3 3 0 1 0 3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"/></g></svg>'
    this.clockIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>'
    this.competeIcon = '<svg fill="#aaa" height="24" width="24" version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 256 256" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_2_" d="M172.1,100.4c-2.6-8.3,2-17.2,10.3-19.8c8.3-2.6,17.2,2,19.8,10.3c2.6,8.3-2,17.2-10.3,19.8 C183.6,113.4,174.8,108.8,172.1,100.4z M11,110.7c8.3,2.6,17.2-2,19.8-10.3c2.6-8.3-2-17.2-10.3-19.8c-8.3-2.6-17.2,2-19.8,10.3 C-1.9,99.2,2.7,108.1,11,110.7z M255.3,126.2c0.4,1.3,0.6,2.7,0.6,4.1l-4,39c-0.8,7.4-4.7,12.3-11.9,14.5L198.4,197 c0,0-9.8,31-9.9,31c-1.3,4.5-5.4,7.8-10.4,7.8c-6,0-10.9-4.9-10.9-10.9c0-1.9,1.4-5.3,1.4-5.3c0.2-0.7,8.7-24.2,12.2-33.9 l-35.4,11.2c0,0-9.8,31-9.9,31c-1.3,4.5-5.4,7.8-10.4,7.8c-6,0-10.9-4.9-10.9-10.9c0-1.9,1.4-5.3,1.4-5.3c0.2-0.9,13.6-38,13.6-38 c1.2-2.3,3.2-3.9,6-4.8l30.4-9.6c0,0,0.9-22.7,0.9-23.1l-22.1,0l-20.6,6.5c-4.4,1.4-9.2-1.1-10.6-5.5c-0.1-0.3-0.2-0.7-0.2-1H89.7 c-0.1,0.3-0.1,0.7-0.2,1c-1.4,4.4-6.1,6.9-10.6,5.5L36.2,137l0.9,30.1l30.4,9.6c2.8,0.9,4.8,2.5,6,4.8c0,0,13.4,37.1,13.6,38 c0,0,1.4,3.4,1.4,5.3c0,6-4.9,10.9-10.9,10.9c-4.9,0-9-3.3-10.4-7.8c0-0.1-9.9-31-9.9-31l-41.5-13.1c-7.2-2.3-11.2-7.1-11.9-14.5 l-4-39c0-1.4,0.2-2.8,0.6-4.1c1.4-4.6,4.3-7.9,8.6-10c4.3-2.1,8.7-2.4,13.3-1L84,134.7c2.2,0.7,3.8,2.3,4.8,4.3h25.4 c1-2,2.7-3.6,4.8-4.3l61.6-19.7c4.6-1.4,9-1.1,13.3,1c3.9,2,6.6,5,8.2,9l31.4-9.9c4.6-1.4,9-1.1,13.3,1 C251,118.3,253.8,121.6,255.3,126.2z M219.7,137.1l-17.9,5.7l0,0l-23.1,7l1.2,3.8l21.6-6.5l-2.3,22.1c-0.2,1.7-0.5,3.2-1,4.6 l20.8-6.6L219.7,137.1z M244.8,110.9c8.3-2.6,12.9-11.5,10.3-19.8c-2.6-8.3-11.5-12.9-19.8-10.3c-8.3,2.6-12.9,11.5-10.3,19.8 C227.6,108.9,236.5,113.5,244.8,110.9z"></path> </g></svg>'
    this.termIcon = '<svg version="1.1" fill="#aaa" height="24" width="24" id="icons_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve"><style>.st0{display:none}.st1{display:inline}.st2{fill:#aaa}</style><g id="row1_1_"><g id="_x32__3_"><path class="st2" d="M64 .3C28.7.3 0 28.8 0 64s28.7 63.7 64 63.7 64-28.5 64-63.7S99.3.3 64 .3zm0 121C32.2 121.3 6.4 95.7 6.4 64 6.4 32.3 32.2 6.7 64 6.7s57.6 25.7 57.6 57.3c0 31.7-25.8 57.3-57.6 57.3zm17.6-60.5H43.2c-2.7 0-4.8 2.1-4.8 4.8 0 2.6 2.2 4.8 4.8 4.8h38.4c2.6 0 4.8-2.1 4.8-4.8 0-2.6-2.1-4.8-4.8-4.8z" id="minus_transparent"/></g></g></svg>'
    this.colourBy = this.$route.params.colourBy || 'validity'
    console.debug('[BehaviourTreeViewer] Selected BT on mount: ', this.selectedBT)
    setTimeout(() => {
      this.getTreeData()
      this.oc(this.$refs.tree, this.nodes)
    }, 800)
  },
  methods: {
    oc(domEl, x) {
      const vueApp = this
      this.chart = new OrgChart(domEl, {
        template: 'bt',
        enableDragDrop: true,
        scaleInitial: 0.9,
        siblingSeparation: 30,
        miniMap: false,
        mouseScrool: OrgChart.action.scroll,
        showYScroll: OrgChart.scroll.visible,
        showXScroll: OrgChart.scroll.visible,
        nodeMouseClick: OrgChart.action.none,
        nodes: x,
        nodeMenu: {
          addMenuItem: {
            text: 'Add Child',
            icon: '',
            onClick(id) {
              console.debug('[BehaviourTreeViewer] Add child to...', id)
              vueApp.addNode(id)
            },
          },
          // editMenuItem: {
          //   text: 'Edit Node',
          //   icon: OrgChart.icon.add(16, 16, '#ccc'),
          //   onClick(id) {
          //     console.debug('[BehaviourTreeViewer] Edit node ...', id)
          //     vueApp.editNode(id)
          //   },
          // },
          deleteMenuItem: {
            text: 'Delete Node',
            icon: '',
            onClick(id) {
              console.debug('[BehaviourTreeViewer] Delete node ...', id)
              vueApp.deleteNode(id)
            },
          },
          timingMenuItem: {
            text: 'Add/Edit Performance',
            icon: '',
            onClick(id) {
              console.debug('[BehaviourTreeViewer] Add Edit ...', id)
              vueApp.editTiming()
            },
          },
          copyMenuItem: {
            text: 'Copy Node',
            icon: '',
            onClick(id) {
              console.debug('[BehaviourTreeViewer] Copy node ...', id)
              vueApp.copyNodeDet(id)
            },
          },
          addPreconditionItem: {
            text: 'Add Precondition',
            icon: '',
            onClick(id) {
              console.debug('[BehaviourTreeViewer] Add precondition to...', id)
              vueApp.addPrecondition(id)
            },
          },
          refineItem: {
            text: 'Refine Behaviour',
            icon: '',
            onClick(id) {
              console.debug('[BehaviourTreeViewer] Refine...', id)
              vueApp.refineBehaviour(id)
            },
          },
          selectWithChildren: {
            text: 'Select With Children',
            icon: '',
            onClick(id) {
              console.debug('[BehaviourTreeViewer] Select With Children...', id)
              vueApp.selectBulk(id, true)
            },
          },
          selectChildren: {
            text: 'Select Children',
            icon: '',
            onClick(id) {
              console.debug('[BehaviourTreeViewer] Select Children...', id)
              vueApp.selectBulk(id, false)
            },
          },
          fnMenuItem: {
            text: 'Instantiate Function',
            icon: vueApp.cogIcon,
            onClick(id) {
              console.log('[BehaviourTreeViewer] Add fn as child of...', id)
              vueApp.selParent = id
              vueApp.$store.dispatch('behaviours/selectBehaviourNode', id)
                .then(() => {
                  vueApp.$bvModal.show('function-select-modal')
                })
            },
          },
          interfaceMenuItem: {
            text: 'Instantiate Interface',
            icon: vueApp.ifIcon,
            onClick(id) {
              console.log('[BehaviourTreeViewer] Add interface as child of...', id)
              vueApp.selParent = id
              vueApp.$store.dispatch('behaviours/selectBehaviourNode', id)
                .then(() => {
                  vueApp.$bvModal.show('instantiateInterfaceModal')
                })
            },
          },

        },
        nodeCircleMenu: {
          reference: {
            icon: vueApp.rightIcon,
            text: 'Reference',
            color: '#fff',
            draggable: true,
          },
          reversion: {
            icon: vueApp.revertIcon,
            text: 'Revert',
            color: '#fff',
            draggable: true,
          },
          synchronise: {
            icon: OrgChart.icon.link(24, 24, '#aeaeae'),
            text: 'Synchronise',
            color: '#fff',
            draggable: true,
          },
          compete: {
            icon: vueApp.competeIcon,
            text: 'Compete',
            color: '#fff',
            draggable: true,
          },
          terminate: {
            icon: vueApp.termIcon,
            text: 'Force Terminate',
            color: '#fff',
            draggable: true,
          },
          time: {
            icon: vueApp.clockIcon,
            text: 'Time',
            color: '#fff',
            draggable: true,
          },
        },
        menu: {
          rgen: {
            icon: vueApp.cogIcon,
            text: 'Generate Requirements',
            onClick() {
              console.log('Generate Requirements from Behaviour Tree: ', vueApp.selectedBT.id)
              vueApp.reqgen = true
              const modelId = vueApp.$store.state.model.id
              vueApp.$bvModal.show('generate-bt-requirements-modal')
              // vueApp.$http.get(`/api/v2/behaviour/generate_frs/${vueApp.selectedBT.id}`, { params: { model: modelId } })
              //   .then(({ data }) => {
              //     vueApp.reqgen = false
              //     vueApp.$toast({
              //       component: ToastificationContent,
              //       props: {
              //         title: 'Requirements Generation Complete',
              //         icon: 'CheckIcon',
              //         text: '...',
              //         variant: 'success',
              //       },
              //     })
              //   })
            },
          },
          tst: {
            icon: vueApp.exportIcon,
            text: 'Generate Tests',
            onClick() {
              console.log('Generate Tests from Behaviour Tree: ', vueApp.selectedBT.id)
              // TODO Open modal to configure test generation (criticality, ...)
              vueApp.testgen = true
              const modelId = vueApp.$store.state.model.id
              vueApp.$http.get(`/api/v2/behaviour/generate_tests/${vueApp.selectedBT.id}`, { params: { model: modelId } })
                .then(({ data }) => {
                  vueApp.testgen = false
                  vueApp.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Test Generation Complete',
                      icon: 'CheckIcon',
                      text: `${data.tests_generated} generated in ${data.time} seconds`,
                      variant: 'success',
                    },
                  })
                })
            },
          },
          cbv: {
            icon: vueApp.tickIcon,
            text: 'Colour - Validity',
            onClick() {
              console.log('Colour by Validity: ', vueApp.selectedBT.id)
              this.$store.dispatch('behaviours/clearSelectedConfigs')
              vueApp.colourBy = 'validity'
              vueApp.refresh()
              vueApp.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Showing Validity',
                  text: 'Behaviour view coloured by validity',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            },
          },
          cbt: {
            icon: vueApp.testIcon,
            text: 'Colour - Test Results',
            onClick() {
              console.log('Colour by Test Results: ', vueApp.selectedBT.id)
              vueApp.$bvModal.show('show-tests-modal')
            },
          },
          cbe: {
            icon: vueApp.testIcon,
            text: 'Colour - Enablement',
            onClick() {
              console.log('Colour by Enablement: ', vueApp.selectedBT.id)
              this.$store.dispatch('behaviours/clearSelectedConfigs')
              vueApp.$bvModal.show('show-enablement-modal')
            },
          },
          hur: {
            icon: vueApp.reqIcon,
            text: 'Highlight Unallocated Behaviours',
            onClick() {
              this.$store.dispatch('behaviours/clearSelectedConfigs')
              vueApp.$bvModal.show('list-spec-modal')
            },
          },
          cbr: {
            // TODO:  Update this
            icon: vueApp.reqIcon,
            text: 'Highlight Requirement Behaviours',
            onClick() {
              this.$store.dispatch('behaviours/clearSelectedConfigs')
              vueApp.$bvModal.show('view-requirement-modal')
            },
          },
          csv: { text: 'Save as CSV' },
          xml: { text: 'Export UML XMI' },

        },
        nodeBinding: {
          component: 'display_cpt_name',
          start_symbol: 'symbol_l',
          end_symbol: 'symbol_r',
          name: 'display_name',
          operator: 'op_str',
        },
        // align: OrgChart.ORIENTATION,
        toolbar: {
          fullScreen: true,
          zoom: true,
          fit: true,
          expandAll: true,
        },
        tags: {
          function_node: {
            template: 'bt_fn_node',
          },
          bt_green: {
            template: 'bt_green',
          },
          bt_yellow: {
            template: 'bt_yellow',
          },
          bt_amber: {
            template: 'bt_amber',
          },
          bt_red: {
            template: 'bt_red',
          },
          bt_white: {
            template: 'bt_white',
          },
          bt_grey: {
            template: 'bt_grey',
          },
          bt_violet: {
            template: 'bt_violet',
          },
          bt_highlight: {
            template: 'bt_highlight',
          },
        },
      })
      vueApp.chart.on('click', (sender, args) => {
        if (args.event.ctrlKey || args.event.metaKey) {
          const nodeElement = sender.getNodeElement(args.node.id)
          if (nodeElement.classList.contains('focused')) {
            nodeElement.classList.remove('focused')
            const index = this.selectedNodeIds.indexOf(args.node.id)
            if (index > -1) {
              this.selectedNodeIds.splice(index, 1)
            }
          } else {
            nodeElement.classList.add('focused')
            this.selectedNodeIds.push(args.node.id)
          }
        } else {
          const focusedElements = document.querySelectorAll('.focused')
          for (let i = 0; i < focusedElements.length; i++) {
            focusedElements[i].classList.remove('focused')
            focusedElements[i].classList.remove('animate-pulse')
          }
          const nodeElement = sender.getNodeElement(args.node.id)
          nodeElement.classList.add('focused')
          nodeElement.classList.add('animate-pulse')
          this.selectedNodeIds = []
          this.$emit('clickNode', args.node.id)
        }
        return false
      })
      vueApp.chart.on('searchclick', (sender, nodeId) => {
        vueApp.chart.center(nodeId, {}, () => {
          const focusedElements = document.querySelectorAll('.focused')
          for (let i = 0; i < focusedElements.length; i++) {
            focusedElements[i].classList.remove('focused')
            focusedElements[i].classList.remove('animate-pulse')
          }
          const nodeElement = vueApp.chart.getNodeElement(nodeId)
          nodeElement.classList.add('focused')
          nodeElement.classList.add('animate-pulse')
          // vueApp.$store.dispatch('domainModel/selectEntity2', nodeId)
        })
        return false
      })
      // eslint-disable-next-line new-cap
      const ddMenu = new OrgChart.menuUI()
      ddMenu.init(vueApp.chart, {
        dismiss: {
          icon: '',
          // icon: OrgChart.icon.close(16, 16, '#ccc'),
          onClick() {
            return null
          },
          text: 'Dismiss menu',
        },
        integrate: {
          icon: '',
          onClick() {
            console.debug('[BehaviourTreeViewer] Integrate')
            vueApp.integrating = true
            vueApp.integrate()
          },
          text: 'Integrate',
        },
        moveSeq: {
          icon: '',
          onClick() {
            console.debug('[BehaviourTreeViewer] Move After')
            vueApp.moving = true
            vueApp.integrate()
          },
          text: 'Move After',
        },
        copyChildren: {
          icon: '',
          onClick() {
            console.debug('[BehaviourTreeViewer] Copy With Children')
            vueApp.copying = true
            vueApp.copyNode(true)
          },
          text: 'Copy After (with children)',
        },
        copyNoChildren: {
          icon: '',
          onClick() {
            console.debug('[BehaviourTreeViewer] Copy Without Children')
            vueApp.copying = true
            vueApp.copyNode(false)
          },
          text: 'Copy After (without children)',
        },
      })

      vueApp.chart.on('drop', (sender, draggedNodeId, droppedNodeId) => {
        if (sender._canUpdateLink(draggedNodeId, droppedNodeId)) {
          vueApp.dragNode = draggedNodeId
          vueApp.dropNode = droppedNodeId

          const rect = sender.getNodeElement(droppedNodeId).getBoundingClientRect()
          console.debug('[BehaviourTreeViewer] Drop rect: ', rect)
          ddMenu.show(rect.left, rect.top)
        }
        return false
      })

      vueApp.chart.nodeCircleMenuUI.on('drop', (sender, args) => {
        console.log('Drop circle menu: ', sender, args)
        if (args.menuItemName === 'time') {
          vueApp.selectedNodeIds = [args.from, args.to]
          vueApp.editTiming()
        } else {
          const params = {
            source: args.from,
            target: args.to,
            bt: vueApp.selectedBT.id,
            relationship: args.menuItemName,
            model: vueApp.$store.state.model.id,
          }
          const source = vueApp.chart.getNode(args.from)
          source.op_str = ''
          const op = vueApp.behaviourOperators.find(e => e.id === args.menuItemName)
          if (op) {
            source.op_str = op.display
            const node = vueApp.chart.get(args.from)
            node.op_str = op.display
            if (args.menuItemName === 'synchronise' || args.menuItemName === 'compete') {
              const nodeT = vueApp.chart.get(args.to)
              nodeT.op_str = op.display
              vueApp.chart.updateNode(nodeT)
            }
            vueApp.chart.updateNode(node)
          }
          axiosIns.post('/api/v2/behaviour/create_bn_relationship', params).then(() => {
            if (args.menuItemName === 'reversion') {
              vueApp.chart.addClink(args.from, args.to, '', 'blue').draw(OrgChart.action.update)
            } else if (args.menuItemName === 'synchronise') {
              vueApp.chart.addSlink(args.from, args.to, '', 'orange').draw(OrgChart.action.update)
            } else {
              vueApp.chart.addSlink(args.from, args.to, '', 'blue').draw(OrgChart.action.update)
            }
          })
        }
      })
      this.focusOnNode()
    },
    focusOnNode() {
      const nodeId = this.$route.params.focus
      if (nodeId) {
        console.debug(`[BehaviourTreeViewer] Focusing on nodeId: ${nodeId}`)
        this.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        this.chart.center(nodeId, {
          vertical: true,
          horizontal: true,
        })
        const nodeElement = this.chart.getNodeElement(nodeId)
        nodeElement.classList.add('focused')
        nodeElement.classList.add('animate-pulse')
        this.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
      }
    },
    focusOnThisNode() {
      const nodeId = this.bnFocus
      if (nodeId && nodeId !== '') {
        this.chart.center(nodeId, {
          vertical: true,
          horizontal: true,
        })
        const nodeElement = this.chart.getNodeElement(nodeId)
        nodeElement.classList.add('focusedA')
        nodeElement.classList.add('animate-pulse')
      }
    },
    reFocusNode() {
      const nodeId = this.reFocus
      if (nodeId && nodeId !== '') {
        this.chart.center(nodeId, {
          vertical: true,
          horizontal: true,
        })
        const nodeElement = this.chart.getNodeElement(nodeId)
        nodeElement.classList.add('focused')
        nodeElement.classList.add('animate-pulse')
      }
    },
    updateRefSymbology() {
      const vueApp = this
      const targetId = vueApp.refTarget
      const sourceId = vueApp.selectedNode.details.id
      const rType = vueApp.refType
      console.log('Trying to update reference visually: ', sourceId, targetId, rType)
      const source = vueApp.chart.getNode(sourceId)
      source.op_str = ''
      const op = vueApp.behaviourOperators.find(e => e.id === rType)
      if (op) {
        source.op_str = op.display
        const node = vueApp.chart.get(sourceId)
        node.op_str = op.display
        if (rType === 'synchronise' || rType === 'compete') {
          const nodeT = vueApp.chart.get(targetId)
          nodeT.op_str = op.display
          vueApp.chart.updateNode(nodeT)
        }
        vueApp.chart.updateNode(node)
      }
      if (rType === 'reversion') {
        vueApp.chart.addClink(sourceId, targetId, '', 'blue').draw(OrgChart.action.update)
      } else if (rType === 'synchronise') {
        vueApp.chart.addSlink(sourceId, targetId, '', 'orange').draw(OrgChart.action.update)
      } else {
        vueApp.chart.addSlink(sourceId, targetId, '', 'blue').draw(OrgChart.action.update)
      }
    },
    updateRefinement() {
      const vueApp = this
      const sourceId = vueApp.selectedNode.details.id
      const source = vueApp.chart.getNode(sourceId)
      const n = vueApp.nodeMap[sourceId]
      const node = this.getNodeBits(n)
      node.operator = 'refined'
      vueApp.chart.updateNode(node)
      const nodeElement = vueApp.chart.getNodeElement(node.id)
      nodeElement.classList.add('focused')
      vueApp.focusNode = node.id
    },
    getNodeBits(x) {
      const vueApp = this
      x.tags = []

      const rootTag = 'bt'
      if (!vueApp.colourBy || vueApp.colourBy === '' || vueApp.colourBy === 'validity') {
        let t = `${rootTag}_white`
        if (x.validity === 'Valid') {
          t = `${rootTag}_green`
        } else if (x.validity === 'Minor Assumption') {
          t = `${rootTag}_yellow`
        } else if (x.validity === 'Implied') {
          t = `${rootTag}_amber`
        } else if (x.validity === 'Invalid') {
          t = `${rootTag}_red`
        }
        x.tags.push(t)

        const key = document.getElementById('tree-key')
        key.innerHTML = `
          <h4>Key - Coloured by Validity</h4>
          <h6>
            <b-badge style="background-color: #D8E9D2; color: #2d2d2d">
              Valid
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFF2CB; color: #2d2d2d">
              Minor Uncertainty
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFE497; color: #2d2d2d">
              Moderate Uncertainty
            </b-badge>&nbsp;
            <b-badge style="background-color: #F3CBCB; color: #2d2d2d">
              Major Uncertainty
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFFFFF; color: #2d2d2d">
              Refinement
            </b-badge>
          </h6>`
      } else if (vueApp.colourBy === 'testedness') {
        let t = `${rootTag}_grey`
        const tr = vueApp.testedness[x.id] || 'No Tests'
        if (tr === 'Passed') {
          t = `${rootTag}_green`
        } else if (tr === 'Failed') {
          t = `${rootTag}_red`
        } else if (tr === 'Inconclusive' || tr === 'No Run' || tr === 'Blocked' || tr === 'N/A') {
          t = `${rootTag}_yellow`
        } else if (tr === 'Partial') {
          t = `${rootTag}_violet`
        } else {
          t = `${rootTag}_grey`
        }
        x.tags.push(t)

        const key = document.getElementById('tree-key')
        key.innerHTML = `
          <h4>Key - Coloured by Test Execution Results</h4>
          <h6>
            <b-badge style="background-color: #D8E9D2; color: #2d2d2d">
              Passed ${this.passPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #F3CBCB; color: #2d2d2d">
              Failed ${this.failPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #D0BDF0; color: #2d2d2d">
              Partial ${this.partialPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFF2CB; color: #2d2d2d">
              Not Executed ${this.noRunPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #D8D8D8; color: #2d2d2d">
              No Tests Defined ${this.naPercentage}%
            </b-badge>
          </h6>`
      } else if (vueApp.colourBy === 'enablement-status') {
        let t = `${rootTag}_grey`
        const tr = vueApp.enablement[x.id] || 'No Enablers'
        if (tr === 'Fully Operational') {
          t = `${rootTag}_green`
        } else if (tr === 'Failed / Non-operational') {
          t = `${rootTag}_red`
        } else if (tr === 'Degraded (Minor)') {
          t = `${rootTag}_yellow`
        } else if (tr === 'Degraded (Significant)') {
          t = `${rootTag}_amber`
        } else if (tr === 'Unevaluated') {
          t = `${rootTag}_violet`
        } else {
          t = `${rootTag}_grey`
        }
        x.tags.push(t)

        const key = document.getElementById('tree-key')
        key.innerHTML = `
          <h4>Key - Coloured by Enabler's operational status</h4>
          <h6>
            <b-badge style="background-color: #D8E9D2; color: #2d2d2d">
              Fully Functional ${this.onePercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFF2CB; color: #2d2d2d">
              Degraded (Minor) ${this.threePercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFE497; color: #2d2d2d">
              Degraded (Significant) ${this.fourPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #F3CBCB; color: #2d2d2d">
              Non-Operational ${this.fivePercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #D0BDF0; color: #2d2d2d">
              Unevaluated ${this.partialPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #D8D8D8; color: #2d2d2d">
              No Enablers ${this.naPercentage}%
            </b-badge>
          </h6>`
      } else if (vueApp.colourBy === 'enablement-trl') {
        let t = `${rootTag}_grey`
        const tr = vueApp.enablement[x.id] || 'No Enablers'
        if (tr === 'Proven') {
          t = `${rootTag}_green`
        } else if (tr === 'Qualified') {
          t = `${rootTag}_white`
        } else if (tr === 'Prototype') {
          t = `${rootTag}_yellow`
        } else if (tr === 'Concept') {
          t = `${rootTag}_amber`
        } else if (tr === 'Research') {
          t = `${rootTag}_red`
        } else if (tr === 'Unevaluated') {
          t = `${rootTag}_violet`
        } else {
          t = `${rootTag}_grey`
        }
        x.tags.push(t)

        const key = document.getElementById('tree-key')
        key.innerHTML = `
          <h4>Key - Coloured by Enabler's TRL</h4>
          <h6>
            <b-badge style="background-color: #D8E9D2; color: #2d2d2d">
              TRL 9: ${this.onePercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFFFFF; color: #2d2d2d">
              TRL 8: ${this.twoPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFF2CB; color: #2d2d2d">
              TRL 5 - 7: ${this.threePercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFE497; color: #2d2d2d">
              TRL 3 - 4: ${this.fourPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #F3CBCB; color: #2d2d2d">
              TRL 1 - 2: ${this.fivePercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #D0BDF0; color: #2d2d2d">
              TRL Unevaluated: ${this.partialPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #D8D8D8; color: #2d2d2d">
              No Enablers: ${this.naPercentage}%
            </b-badge>
          </h6>`
      }
      if (x.type === 'FunctionNode') {
        x.tags = ['function_node']
        x.display_cpt_name = x.behaviour_name
      }
      // this needs to be a param or dynamically set thing
      if (x.highlight) {
        const ht = `${rootTag}_highlight`
        x.tags = [ht]
      }

      // Create a display name for the behaviour
      if (x.type === 'Quantification') {
        if (x.behaviour_name === '||') {
          x.display_name = `For each ${x.display_cpt_name} in ${x.set}`
        } else {
          x.display_name = `If some ${x.display_cpt_name} in ${x.set}`
        }
      } else if (x.type === 'Quantity') {
        x.display_name = `For up to ${x.quantity} of ${x.display_cpt_name} in ${x.set}`
      } else if (x.type === 'Input' || x.type === 'Output') {
        x.display_name = x.io_resource_name ? `<p>${x.io_resource_name}</p>` : `<p>${x.io_resource}</p>`
      } else {
        x.display_name = (x.negated && (x.negated === 'true' || x.negated === 'True' || x.negated === true)) ? `<p>NOT (<i>${x.behaviour_name})</i>` : `<p><i>${x.behaviour_name}</i>`
      }

      if (x.type !== 'Quantification') {
        if (x.type === 'Input' || x.type === 'Output') {
          // Create a display name for rel parts
          if (x.rel_parts && x.rel_parts.length > 0) {
            x.rel_parts.forEach(r => {
              x.display_name += `<p class="rel">${r.name}</p>`
            })
          }
        } else {
          x.rel_parts.forEach(r => {
            x.display_name += (r && r.preposition !== '') ? ` ${r.preposition}` : ''
            if (r.instance_name && r.instance_name !== '') {
              x.display_name += ` <b>${r.object_name}</b>#${r.instance_name}`
            } else {
              x.display_name += ` <b>${r.object_name}</b>`
            }
            if (r.attribute && r.attribute !== '') {
              x.display_name += `.${r.attribute}`
            }
          })
          x.display_name += '</p>'
        }
      }

      // create an operator string
      x.op_str = ''
      const op = this.behaviourOperators.find(e => e.id === x.operator)
      if (op) {
        x.op_str = op.display
      }

      console.log('The Bits: ', x)

      return x
    },
    getTreeData() {
      const vueApp = this
      const nn = []
      vueApp.selectedBT.nodes.forEach(x => {
        x = this.getNodeBits(x)
        vueApp.nodeMap[x.id] = x

        if (x.is_root) {
          nn.push(x)
        }
      })

      const en = vueApp.selectedBT.edges
      en.forEach(e => {
        const n = vueApp.nodeMap[e.target]
        n.pid = e.source
        nn.push(n)
      })
      console.debug('[BehaviourTreeViewer] Test graph: ', nn)
      vueApp.nodes = nn
    },
    integrate() {
      const vueApp = this
      console.debug('Integrating ', this.dragNode, ' to ', this.dropNode)
      const node = vueApp.chart.get(this.dragNode)
      axiosIns.post('/api/v2/behaviour/integrate', {
        model: vueApp.$store.state.model.id,
        source: vueApp.dragNode,
        target: vueApp.dropNode,
        source_tree: vueApp.selectedBT.id,
        rel_type: 'sequence',
      }).then(({ data }) => {
        vueApp.$store.dispatch('behaviours/selectBehaviourTree', vueApp.selectedBT.id).then(() => {
          setTimeout(() => {
            vueApp.getTreeData()
            vueApp.oc(this.$refs.tree, this.nodes)
            vueApp.focusNode = vueApp.dropNode
            vueApp.integrating = false
            vueApp.moving = false
          }, 800)
        })
      })
    },
    instantiateInterface(data) {
      const vueApp = this
      axiosIns.post('/api/v2/behaviour/instantiate_interface', {
        interface: data,
        target: vueApp.selParent,
        bt: vueApp.selectedBT.id,
        parent_rel: 'sequence',
        model: vueApp.$store.state.model.id,
      }).then(({ data }) => {
        vueApp.$bvModal.hide('instantiateInterfaceModal')
        vueApp.$store.dispatch('behaviours/selectBehaviourTree', vueApp.selectedBT.id).then(() => {
          setTimeout(() => {
            console.debug('BT on refresh: ', this.composition_tree)
            vueApp.getTreeData()
            vueApp.oc(this.$refs.tree, this.nodes)
            vueApp.focusNode = data.length > 0 ? data[0] : vueApp.selParent
          }, 800)
        })
      })
    },
    instantiateFn1(data) {
      console.log('Selecting Function: ', data)
      const params = { model: this.$store.state.model.id }
      this.$http.get(`/api/v2/domain_model/entity/${data}`, { params })
        .then(response => {
          const fnCpt = response.data
          console.log('Selected Function: ', fnCpt)
          this.instFnPerformers = fnCpt.context.relations.edges.filter(e => e.name === 'Performs').map(x => x.source)
          this.instFnName = fnCpt.context.details.name
          this.instFnReqs = fnCpt.context.relationships.filter(e => e.labels.includes('Requirement')).map(x => x.target_props.id)
          this.instFnIss = fnCpt.context.relationships.filter(e => e.labels.includes('Issue')).map(x => x.target_props.id)
          console.log('Open Instantiate Modal With: ', this.instFnPerformers, this.instFnName)
          this.$bvModal.hide('function-select-modal')
          this.$bvModal.show('instantiate-fn-modal')
        })
    },
    copyNode(withChildren = true) {
      const vueApp = this
      const fields = {
        source: vueApp.dragNode,
        target: vueApp.dropNode,
        bt: vueApp.selectedBT.id,
        rel_type: 'sequence',
        with_children: withChildren,
      }
      axiosIns.post('/api/v2/behaviour/copy_node', fields).then(({ data }) => {
        vueApp.$store.dispatch('behaviours/selectBehaviourTree', vueApp.selectedBT.id).then(() => {
          setTimeout(() => {
            vueApp.getTreeData()
            vueApp.oc(this.$refs.tree, this.nodes)
            vueApp.focusNode = vueApp.dropNode
            vueApp.copying = false
          }, 800)
        })
      })
    },
    postCopy(node) {
      console.debug('[BehaviourTreeViewer] Copy callback: ', node)
      this.dragNode = this.selected_entity2.context.details.id
      this.dropNode = node.selected_parent
      if (node.parent_rel === 'aggregation') {
        this.copyAggregate()
      } else {
        this.copySubType()
      }
    },
    showTests(data) {
      console.log('Testedness: ', data)
      this.colourBy = 'testedness'
      this.testedness = data
      let p = 0
      let f = 0
      let ne = 0
      let na = 0
      let part = 0
      Object.keys(data).forEach(key => {
        console.log(`${key} ${data[key]}`)
        if (data[key] === 'No Tests') {
          na++
        } else if (data[key] === 'Passed') {
          p++
        } else if (data[key] === 'Failed') {
          f++
        } else if (data[key] === 'Partial') {
          part++
        } else if (data[key] === 'Inconclusive' || data[key] === 'No Run' || data[key] === 'Blocked' || data[key] === 'N/A') {
          ne++
        } else {
          na++
        }
      })
      const total = p + f + ne + na + part + 0
      this.passPercentage = ((p * 100) / total).toFixed(1)
      this.failPercentage = ((f * 100) / total).toFixed(1)
      this.noRunPercentage = ((ne * 100) / total).toFixed(1)
      this.partialPercentage = ((part * 100) / total).toFixed(1)
      this.naPercentage = ((na * 100) / total).toFixed(1)
      this.refresh()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Showing Test Results',
          text: 'Behaviour view coloured by test results',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    showEnablement(data) {
      console.log('Enablement: ', data)
      this.colourBy = `enablement-${data.config}`
      this.enablement = data.data
      if (data.config === 'trl') {
        let research = 0
        let feasible = 0
        let prototype = 0
        let qualified = 0
        let proven = 0
        let unevaluated = 0
        let na = 0
        Object.keys(data.data).forEach(key => {
          console.log(`${key} ${data.data[key]}`)
          if (data.data[key] === 'No Enablers') {
            na++
          } else if (data.data[key] === 'Research') {
            research++
          } else if (data.data[key] === 'Concept') {
            feasible++
          } else if (data.data[key] === 'Prototype') {
            prototype++
          } else if (data.data[key] === 'Qualified') {
            qualified++
          } else if (data.data[key] === 'Proven') {
            proven++
          } else if (data.data[key] === 'Unevaluated') {
            unevaluated++
          } else {
            na++
          }
        })
        const total = research + feasible + prototype + qualified + proven + unevaluated + na + 0
        this.onePercentage = ((proven * 100) / total).toFixed(1)
        this.twoPercentage = ((qualified * 100) / total).toFixed(1)
        this.threePercentage = ((prototype * 100) / total).toFixed(1)
        this.fourPercentage = ((feasible * 100) / total).toFixed(1)
        this.fivePercentage = ((research * 100) / total).toFixed(1)
        this.partialPercentage = ((unevaluated * 100) / total).toFixed(1)
        this.naPercentage = ((na * 100) / total).toFixed(1)
      } else {
        console.log('Config: ', data.config)
        let functional = 0
        let failed = 0
        let degraded1 = 0
        let degraded2 = 0
        let unevaluated = 0
        let na = 0
        Object.keys(data.data).forEach(key => {
          console.log(`${key} ${data.data[key]}`)
          if (data.data[key] === 'No Enablers') {
            na++
          } else if (data.data[key] === 'Fully Operational') {
            functional++
          } else if (data.data[key] === 'Degraded (Minor)') {
            degraded1++
          } else if (data.data[key] === 'Degraded (Significant)') {
            degraded2++
          } else if (data.data[key] === 'Failed / Non-operational') {
            failed++
          } else {
            unevaluated++
          }
        })
        const total = functional + failed + degraded1 + degraded2 + unevaluated + na + 0
        this.onePercentage = ((functional * 100) / total).toFixed(1)
        this.fivePercentage = ((failed * 100) / total).toFixed(1)
        this.threePercentage = ((degraded1 * 100) / total).toFixed(1)
        this.fourPercentage = ((degraded2 * 100) / total).toFixed(1)
        this.partialPercentage = ((unevaluated * 100) / total).toFixed(1)
        this.naPercentage = ((na * 100) / total).toFixed(1)
      }
      this.refresh()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Showing Enablement Results',
          text: 'Behaviour view coloured by enablement',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    addNode(nodeId) {
      const vueApp = this
      vueApp.selParent = nodeId
      vueApp.$store.dispatch('domainModel/getComponentsSimple')
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('add-behaviour-modal')
        })
    },
    addPrecondition(nodeId) {
      const vueApp = this
      this.selNode = nodeId
      this.selParent = vueApp.chart.getNode(nodeId).pid
      console.log('[BehaviourTreeViewer] Adding Precondition after ', this.selParent, 'and before', this.selNode)
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('add-precond-modal')
        })
    },
    editNode(nodeId) {
      const vueApp = this
      this.selNode = nodeId
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('edit-behaviour-modal')
        })
    },
    editTiming() {
      const vueApp = this
      console.log('Selected: ', this.selectedNodeIds)
      if (this.selectedNodeIds.length <= 1) {
        // eslint-disable-next-line
        alert('To add performance timing, you must select at least a start and end node.')
      } else {
        vueApp.$bvModal.show('timed-bn-modal')
      }
    },
    deleteNode(nodeId) {
      const vueApp = this
      vueApp.selParent = vueApp.chart.getNode(nodeId).pid
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('delete-behaviour-modal')
        })
    },
    copyNodeDet(nodeId) {
      const vueApp = this
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('copy-node-modal')
        })
    },
    refineBehaviour(nodeId) {
      const vueApp = this
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('refine-node-modal')
        })
      // const fields = {
      //   bn: nodeId,
      //   bt: vueApp.selectedBT.id,
      //   model: vueApp.$store.state.model.id,
      // }
      // axiosIns.post('/api/v2/behaviour/refine_new', fields).then(({ data }) => {
      //   let node = vueApp.chart.getNode(nodeId)
      //   console.log('Update refined node details: ', node)
      //   const n = vueApp.nodeMap[nodeId]
      //   node = this.getNodeBits(n)
      //   node.operator = 'refined'
      //   vueApp.chart.updateNode(node)
      //   const nodeElement = vueApp.chart.getNodeElement(node.id)
      //   nodeElement.classList.add('focused')
      //   vueApp.focusNode = node.id
      // })
    },
    selectBulk(nodeId, withSelf = true) {
      this.selectedNodeIds = []
      if (withSelf) {
        this.selectedNodeIds.push(nodeId)
      }
      // get behaviour node children and add to selected node ids
      this.$http
        .get(`/api/v2/behaviour/get_bn_descendents/${nodeId}/${this.selectedBT.id}`).then(({ data }) => {
          data.forEach(d => {
            this.selectedNodeIds.push(d.id)
          })
          // Highlight selected nodes
          const focusedElements = document.querySelectorAll('.focused')
          for (let i = 0; i < focusedElements.length; i++) {
            focusedElements[i].classList.remove('selectedfocus')
            focusedElements[i].classList.remove('focus')
            focusedElements[i].classList.remove('focusA')
          }
          if (this.selectedNodeIds.length > 0) {
            console.log('Highlighting selected: ', this.selectedNodeIds)
            this.selectedNodeIds.forEach(n => {
              const nodeElement = this.chart.getNodeElement(n)
              if (nodeElement) {
                nodeElement.classList.add('selectedfocus')
              }
            })
            this.$emit('bulkSelect', this.selectedNodeIds)
          }
        })
    },
    highlight_nodes(nodes) {
      // Highlight selected nodes
      console.log('Nodes to highlight: ', nodes)
      const focusedElements = document.querySelectorAll('.focused')
      for (let i = 0; i < focusedElements.length; i++) {
        focusedElements[i].classList.remove('selectedfocus')
        focusedElements[i].classList.remove('focus')
        focusedElements[i].classList.remove('focusA')
      }

      nodes.forEach(n => {
        const nodeElement = this.chart.getNodeElement(n.id)
        if (nodeElement) {
          nodeElement.classList.add('selectedfocus')
        }
      })
    },
    highlight_unallocated_nodes(spec) {
      this.highlighting = true
      this.$bvModal.hide('list-spec-modal')
      // Lookup unallocated nodes for spec and BT
      const params = {
        model: this.$store.state.model.id,
        specification: spec.id || null,
      }
      this.$http.get(`/api/v2/behaviour/get_unallocated_nodes/${this.selectedBT.id}`, { params })
        .then(({ data }) => {
          const { unallocated } = data
          console.log('Nodes to highlight: ', unallocated)
          // unhighlight anything already higlighted
          const focusedElements = document.querySelectorAll('.focused')
          for (let i = 0; i < focusedElements.length; i++) {
            focusedElements[i].classList.remove('selectedfocus')
            focusedElements[i].classList.remove('focus')
            focusedElements[i].classList.remove('focusA')
          }
          // highlight unallocated nodes
          unallocated.forEach(n => {
            const nodeElement = this.chart.getNodeElement(n)
            if (nodeElement) {
              nodeElement.classList.add('focusedA')
            }
          })
          this.highlighting = false
        })
    },
    postAdd(data) {
      const vueApp = this
      const pid = vueApp.selParent
      data.nodes.forEach(n => {
        // Determine if parent id in already known nodes - if so, use it, otherwise connect to root of add
        if (!(n.pid in vueApp.nodeMap)) {
          n.pid = pid
        }
        const node = this.getNodeBits(n)
        vueApp.chart.addNode(node)
        vueApp.nodeMap[n.id] = node // Update known nodes
      })
      if (data.nodes.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        vueApp.selNode = data.nodes[0].id
      }
      vueApp.$bvModal.hide('add-behaviour-modal')
      vueApp.$bvModal.hide('add-precond-modal')
      vueApp.$bvModal.hide('instantiate-fn-modal')
      vueApp.focusNode = vueApp.selNode
    },
    postAddPrecond(data) {
      const vueApp = this
      // Connect up precond nodes to previous parent
      let pid = vueApp.selParent
      data.nodes.forEach(n => {
        n.pid = pid
        const node = this.getNodeBits(n)
        vueApp.chart.addNode(node)
        pid = n.id
      })

      // connect last node to the node we are adding precond to
      const node = vueApp.chart.get(vueApp.selNode)
      node.pid = pid
      console.log('Node to reassign: ', node)
      vueApp.chart.updateNode(node)

      // Set the selected node to the first precondition node
      if (data.nodes.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        vueApp.selNode = data.nodes[0].id
      }
      vueApp.$bvModal.hide('add-precond-modal')
      vueApp.focusNode = vueApp.selNode
    },

    postUpdate() {
      const vueApp = this
      vueApp.$bvModal.hide('edit-behaviour-modal')

      // update any referencing or derived nodes
      if (this.updateObject.other_nodes) {
        this.updateObject.other_nodes.forEach(nn => {
          let oNode = vueApp.chart.getNode(nn.id)
          console.log('Update other node details: ', oNode)
          const oPid = oNode.pid
          oNode = this.getNodeBits(nn)
          oNode.pid = oPid
          vueApp.chart.updateNode(oNode)
        })
      }

      const nodeData = this.selectedNode.details
      let node = vueApp.chart.getNode(this.selectedNode.details.id)
      console.log('Update details: ', node)
      const { pid } = node
      node = this.getNodeBits(this.updateObject)
      node.pid = pid
      vueApp.chart.updateNode(node)

      // Remove reference links if the operator is ""
      const { clinks } = vueApp.chart.config
      const { slinks } = vueApp.chart.config
      const checkS = []
      if (node.operator === 'no_operator') {
        // Handle links from
        slinks.filter(s => s.from === node.id).forEach((l, lInd) => {
          checkS.push(l.to)
          vueApp.chart.removeSlink(l.from, l.to).draw(OrgChart.action.update)
        })
        clinks.filter(s => s.from === node.id).forEach((l, lInd) => {
          vueApp.chart.removeClink(l.from, l.to).draw(OrgChart.action.update)
        })
        // If the check ends have nothing else pointing to them and they have a synchronise or compete decorator,
        // remove the decorator
        checkS.forEach((nn, nInd) => {
          const nodeT = vueApp.chart.get(nn)
          console.log('Target: ', nodeT)
          if (
            ['compete', 'synchronise', 'no_operator'].includes(nodeT.operator)
            && slinks.filter(s => s.to === nn).length === 0
          ) {
            nodeT.op_str = ''
            nodeT.operator = 'no_operator'
            vueApp.chart.updateNode(nodeT)
          }
        })

        // Handle links to
        console.log('slinks: ', slinks, node)
        slinks.filter(s => s.to === node.id).forEach((l, lInd) => {
          // only remove the link if the from node is synching/competing with the 'un-operatored' node
          const nodeT = vueApp.chart.get(l.from)
          if (
            ['compete', 'synchronise', 'no_operator'].includes(nodeT.operator)
          ) {
            vueApp.chart.removeSlink(l.from, l.to).draw(OrgChart.action.update)
            nodeT.op_str = ''
            nodeT.operator = 'no_operator'
            vueApp.chart.updateNode(nodeT)
          }
        })
      }
      const nodeElement = vueApp.chart.getNodeElement(node.id)
      nodeElement.classList.add('focused')
      vueApp.focusNode = node.id
    },
    postTiming(data) {
      console.log('PostTiming: ', data)
      const vueApp = this
      const nodeElement = vueApp.chart.getNodeElement(data.start)
      nodeElement.classList.add('focused')
      vueApp.focusNode = data.start
      this.chart.addSlink(data.start, data.end, data.label, 'orange').draw(OrgChart.action.update)
    },
    postDel(data) {
      const vueApp = this
      data.deleted.forEach(n => {
        vueApp.chart.removeNode(n)
      })
      vueApp.focusNode = vueApp.selParent
    },
    mergeNode(nodeId) {
      const vueApp = this
      const node = vueApp.chart.getNode(nodeId)
      console.debug('[BehaviourTreeViewer] Merging: ', node)
      vueApp.selNode = nodeId
      vueApp.$store.dispatch('domainModel/selectEntity2', nodeId)
        .then(() => {
          vueApp.$bvModal.show('merge-entity-modal')
        })
    },
    postMerge(node) {
      const vueApp = this
      const nodeId = node.focus
      vueApp.chart.removeNode(vueApp.selNode)
      vueApp.$store.dispatch('domainModel/selectEntity2', nodeId)
        .then(() => {
          vueApp.chart.center(nodeId, {}, () => {
            const focusedElements = document.querySelectorAll('.focused')
            for (let i = 0; i < focusedElements.length; i++) {
              focusedElements[i].classList.remove('focused')
            }
            const nodeElement = vueApp.chart.getNodeElement(nodeId)
            nodeElement.classList.add('focused')
          })
        })
    },
    allocate(nodeId) {
      const vueApp = this
      vueApp.$store.dispatch('domainModel/selectEntity2', nodeId)
        .then(() => {
          console.debug('[BehaviourTreeViewer] Allocating ', nodeId)
          vueApp.$bvModal.show('allocate-function-modal')
        })
    },
    refresh() {
      const vueApp = this
      vueApp.$store.dispatch('behaviours/selectBehaviourTree', vueApp.selectedBT.id).then(() => {
        setTimeout(() => {
          vueApp.getTreeData()
          vueApp.oc(this.$refs.tree, this.nodes)
          vueApp.focusNode = vueApp.selParent
        }, 800)
      })
    },
    routeReference() {
      if (this.selectedNode.refinements && this.selectedNode.refinements.length > 0) {
        const btN = this.selectedNode.refinements[0].bt.id
        this.$store.dispatch('behaviours/selectBehaviourTree', btN)
      } else {
        console.error('Do something with references and reversions')
      }
    },
  },
  template: false,
}
</script>

<style>
#tree{
  display: block;
  height: 71vh !important; /* FIXME:*/
  overflow-y: scroll;
}
.focused rect{
  fill: #42A5F5;
  stroke-width:2;
}
.focusedA rect{
  fill: #B1A2CA;
  stroke-width:2;
}
.selectedfocus rect{
  fill: skyblue;
  stroke-width:2;
}
p.rel{
  border-style: dotted none none none;
  border-width: 0.3px;
  border-spacing: 0px;
}
rect.rel{
    stroke-width: 0.3px;
    stroke-dasharray: 0 300;
}
</style>
