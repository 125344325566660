<template>
  <b-modal
    id="delete-note-modal"
    title="Delete Note"
    centered
  >
    <p>Are you sure you want to delete the note:
      <span class="text-primary font-weight-bold">{{ note }}</span>
      ?
    </p>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="danger" @click="submitFunction()">
        Delete
      </b-button>
      <b-button variant="outline-dark" @click="cancel()">
        Cancel
      </b-button>

    </template>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
import { BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
  },
  props: {
    focusedEntityId: {
      /* Entity to associate/de-associate Items */
      required: true,
      type: String,
    },
    urlAssociated: {
      /* API endpoint to GET and POST Items for this entity type */
      required: true,
      type: String,
    },
    note: {
      required: true,
      type: String,
    },
  },
  methods: {
    submitFunction() {
      console.log('Delete Note: ')
      const payload = {
        model: this.$store.state.model.id,
      }
      this.$http
        .delete(this.urlAssociated)
        .then(() => {
          this.$store.dispatch('behaviours/selectBehaviourNode', this.focusedEntityId)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted note',
              text: 'Successfully deleted a note for the behaviour node',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('delete-note-modal')
        })
        .catch(r => {
          this.clearForm()
          console.error(`[DeleteNoteModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete Note',
              text: `An error occurred when attempting to deleteS a note with the behaviour node.
      Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
