<template>
  <b-modal
    id="allocate-notes-modal"
    title="Create note"
    size="lg"
    lazy
    @shown="onShown"
    @ok="onSubmit"
    @cancel="clearForm"
  >

    <b-row>
      <b-col>
        <tip-tap-editor
          id="associate-note"
          v-model="content"
          min-height="12"
          max-height="12"
        />
        <!-- FIXME tip-tap-editor - min-height: 12rem -->
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>

      <b-button variant="success" :disabled="loadingSubmit" @click="ok()">
        <span v-if="loadingSubmit">
          <b-spinner small type="grow" />
          Creating...
        </span>
        <span v-else>Create note</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  components: {
    TipTapEditor,
  },
  props: {
    focusedEntityId: {
      /* Entity to associate/de-associate Items */
      required: true,
      type: String,
    },
    urlAssociated: {
      /* API endpoint to GET and POST Items for this entity type */
      required: true,
      type: String,
    },
  },
  data: () => ({
    content: '',
    loadingSubmit: false,
  }),
  methods: {
    onShown() {
      this.loadingSubmit = false
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.loadingSubmit = true

      const payload = {
        model: this.$store.state.model.id,
        note: this.content,
      }
      this.$http
        .post(this.urlAssociated, payload)
        .then(() => {
          this.$store.dispatch('behaviours/selectBehaviourNode', this.focusedEntityId)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Created note',
              text: 'Successfully created a note for the behaviour node',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('allocate-notes-modal')
          this.clearForm()
        })
        .catch(r => {
          this.clearForm()
          console.error(`[AssociateNoteModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to save Note',
              text: `An error occurred when attempting to save a note with the behaviour node.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    clearForm() {
      this.content = ''
      this.loadingSubmit = false
    },
  },
}
</script>
