import { render, staticRenderFns } from "./InterfaceSelect.vue?vue&type=template&id=4b390c05"
import script from "./InterfaceSelect.vue?vue&type=script&lang=js"
export * from "./InterfaceSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports