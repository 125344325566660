<template>
  <GenericAssociator
    id="associate-requirements-modal"
    :fetch-filter-fn="fetchSpecifications"
    :fetch-fn="specId => fetchAvailableRequirements(specId)"
    :update-fn="payload => onSave(payload)"
    prefilter-label="title"
    prefilter-name="Specification"
    :label-filter-enabled="false"
    :initial-list="initialLinkedRequirements"
    :instant-save="false"
    :filter-properties="['text', 'display_id', 'object_text']"
    filter-property="text"
    type-name="Requirements"
  >
    <template #left="cpt">
      <span :title="cpt.display_id" class="mr-1 text-ellipsis">{{ cpt.display_id }}: {{ cpt.text || '(empty)' }}</span>
    </template>
    <template #right="{ cpt }">
      <div class="req-row">
        <h6 class="text-primary">{{ cpt.display_id }}</h6>
        <div v-sanitized-html="cpt.object_text || cpt.text || '(empty)'"></div>
      </div>
    </template>
  </GenericAssociator>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'

export default {
  name: 'RequirementAssociator',
  components: {
    GenericAssociator,
  },
  props: {
    isModal: {
      type: Boolean,
      default: true,
    },
    initialLinkedRequirements: {
      type: Array,
      required: true,
    },
    removeFn: {
      type: Function,
      required: false,
      default: () => false,
    },
    updateFn: {
      type: Function,
      required: true,
    },
  },
  setup(props, context) {
    const fetchSpecifications = async () => {
      await store
        .dispatch('specifications/fetchSpecificationsSimple')
      return store.state.specifications.specifications
    }

    const fetchAvailableRequirements = async specId => {
      await store.dispatch('requirements/updateRequirementOptions', specId)
      await store.dispatch('requirements/getRequirementsSimple')
      return store.state.requirements.requirements
        .filter(r => store.state.requirements.requirement_options.find(ro => ro.value === r.id))
    }

    const onSave = reqs => {
      const payload = reqs.map(t => t.id)
      props.updateFn(payload)
    }

    return {
      fetchAvailableRequirements,
      fetchSpecifications,
      onSave,
    }
  },
}
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  max-height: 50vh;
}

.text-ellipsis {
  max-width: 170rem;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  hyphens: auto;
  overflow: clip;
  display: inline-block;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
.req-row img {
  width: 35vw;
  object-fit: scale-down;
}
</style>
